import styled from "styled-components";

import { useState, ChangeEvent, KeyboardEvent, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

import LOGO from "../../assets/images/logo.svg";
import LOGO_SMALL from "../../assets/images/logo_small.svg";
import SEARCH from "../../assets/images/search_icon_24.svg";
import MAIN_HERO_IMAGE from "../../assets/images/main_hero_image.png";
import POPULAR from "../../assets/images/popular_icon.svg";

enum KeyCode {
  Enter = "Enter",
}

enum EventType {
  Click = "click",
  KeyDown = "keydown",
}

const MainPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  const handleSearch = (
    e: KeyboardEvent<HTMLInputElement> | MouseEvent<HTMLImageElement>
  ): void => {
    if (("key" in e && e.key === KeyCode.Enter) || e.type === EventType.Click) {
      if (searchValue.trim()) {
        navigate(`/search?q=${encodeURIComponent(searchValue.trim())}`);
      }
    }
  };

  return (
    <Styles.MainPage>
      <Styles.HeroSection>
        <Styles.HeroContent>
          <Styles.Info>
            <Styles.Text
              $styles={{
                fontSize: "32px",
                fontWeight: "700",
                color: "#28282B",
              }}
            >
              유튜브 모든 영상
            </Styles.Text>

            <Styles.FlexColumnLayout>
              <Styles.Text
                $styles={{
                  fontSize: "56px",

                  fontWeight: "700",
                  color: "#28282B",
                  lineHeight: "76px",
                  textAlign: "center",
                }}
                $mobileStyles={{ fontSize: "36px", lineHeight: "48px" }}
              >
                검색부터 분석까지
              </Styles.Text>
              <Styles.Text
                $styles={{
                  fontSize: "56px",
                  fontWeight: "700",
                  color: "#28282B",
                  lineHeight: "76px",
                  textAlign: "center",
                }}
                $mobileStyles={{ fontSize: "36px", lineHeight: "48px" }}
              >
                쉽고 빠르게
              </Styles.Text>
            </Styles.FlexColumnLayout>

            <Styles.FlexRowLayout
              $styles={{ alignItems: "center", gap: "16px" }}
            >
              <Styles.Text
                $styles={{
                  fontSize: "56px",
                  fontWeight: "700",
                  color: "#0F1114",
                  lineHeight: "76px",
                }}
                $mobileStyles={{ fontSize: "36px", lineHeight: "48px" }}
              >
                답은
              </Styles.Text>
              <Styles.PcVisible>
                <img src={LOGO} alt="Logo" />
              </Styles.PcVisible>
              <Styles.MobileVisible>
                <img src={LOGO_SMALL} alt="Logo" />
              </Styles.MobileVisible>
            </Styles.FlexRowLayout>
          </Styles.Info>

          <Styles.SearchInputWrapper>
            <Styles.SearchIcon
              src={SEARCH}
              alt="Search"
              onClick={handleSearch}
            />
            <Styles.SearchInput
              type="search"
              value={searchValue}
              onChange={handleSearchChange}
              onKeyDown={handleSearch}
              placeholder="키워드 혹은 영상을 입력해 주세요"
            />
          </Styles.SearchInputWrapper>
        </Styles.HeroContent>
      </Styles.HeroSection>

      <Styles.KeywordSection>
        <Styles.PopularWrapper>
          <Styles.FlexRowLayout
            $styles={{ padding: "0px 0px 0px 24px", gap: "8px" }}
          >
            <img src={POPULAR} alt="Logo" />
            <Styles.Text
              $styles={{
                fontSize: "24px",
                fontWeight: "700",
                color: "#282828",
                lineHeight: "28.8px",
              }}
            >
              인기 검색어
            </Styles.Text>
          </Styles.FlexRowLayout>

          <Styles.PopularKeywordGrid>
            <Styles.FlexColumnLayout $styles={{ gap: "16px" }}>
              {Array.from({ length: 5 }, (_, i) => (
                <Styles.KeywordItem key={i}>
                  <Styles.Rank $styles={{ color: "#ff9b21" }}>
                    {i + 1}
                  </Styles.Rank>
                  <Styles.Text
                    $styles={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#232020",
                      lineHeight: "21.6px",
                    }}
                  >
                    키워드 {i + 1}
                  </Styles.Text>
                </Styles.KeywordItem>
              ))}
            </Styles.FlexColumnLayout>

            <Styles.FlexColumnLayout $styles={{ gap: "16px" }}>
              {Array.from({ length: 5 }, (_, i) => (
                <Styles.KeywordItem key={i}>
                  <Styles.Rank $styles={{ color: "#B4C2D4" }}>
                    {i + 6}
                  </Styles.Rank>
                  <Styles.Text
                    $styles={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#232020",
                      lineHeight: "21.6px",
                    }}
                  >
                    키워드 {i + 6}
                  </Styles.Text>
                </Styles.KeywordItem>
              ))}
            </Styles.FlexColumnLayout>

            <Styles.FlexColumnLayout $styles={{ gap: "16px" }}>
              {Array.from({ length: 5 }, (_, i) => (
                <Styles.KeywordItem key={i}>
                  <Styles.Rank $styles={{ color: "#B4C2D4" }}>
                    {i + 11}
                  </Styles.Rank>
                  <Styles.Text
                    $styles={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#232020",
                      lineHeight: "21.6px",
                    }}
                  >
                    키워드 {i + 11}
                  </Styles.Text>
                </Styles.KeywordItem>
              ))}
            </Styles.FlexColumnLayout>

            <Styles.FlexColumnLayout $styles={{ gap: "16px" }}>
              {Array.from({ length: 5 }, (_, i) => (
                <Styles.KeywordItem key={i}>
                  <Styles.Rank $styles={{ color: "#B4C2D4" }}>
                    {i + 16}
                  </Styles.Rank>
                  <Styles.Text
                    $styles={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#232020",
                      lineHeight: "21.6px",
                    }}
                  >
                    키워드 {i + 16}
                  </Styles.Text>
                </Styles.KeywordItem>
              ))}
            </Styles.FlexColumnLayout>
          </Styles.PopularKeywordGrid>
        </Styles.PopularWrapper>
      </Styles.KeywordSection>
    </Styles.MainPage>
  );
};

export default MainPage;

interface StyleProps {
  $styles?: React.CSSProperties | null;
  $mobileStyles?: React.CSSProperties | null;
}

const Styles = {
  MainPage: styled.div`
    font-family: "Pretendard";
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${MAIN_HERO_IMAGE});
    background-repeat: no-repeat;
    background-size: 100% 100%;
  `,

  HeroSection: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #d0d7e6e0;
    padding: 67px 0px 80px 0px;
  `,

  HeroContent: styled.div`
    width: 100%;
    max-width: 854px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    padding: 0px 20px 0px 20px;

    @media (max-width: ${"768px"}) {
      gap: 48px;
    }
  `,

  Info: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    @media (max-width: ${"768px"}) {
      gap: 16px;
    }
  `,

  Text: styled.p<StyleProps>`
    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  SearchInputWrapper: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 20px 24px 20px 24px;
    border: 2px solid transparent;
    border-radius: 50px;
    background: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    background-image: linear-gradient(#fff, #fff),
      linear-gradient(to right, #ff9b21, #ffc07b);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    &:hover {
      outline: none;
      border-color: transparent;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    }
  `,

  SearchInput: styled.input`
    width: 100%;
    border: none;
    font-size: 18px;
    font-weight: 500;
    background: transparent;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #9b9b9b;
    }
  `,

  SearchIcon: styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
  `,

  KeywordSection: styled.div`
    width: 100%;
    padding: 0px 0px 138px 0px;
    display: flex;
    justify-content: center;
    background: #fefefe;
  `,

  PopularWrapper: styled.div`
    width: 100%;
    max-width: 845px;
    margin: 40px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,

  PopularKeywordGrid: styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4개의 열 */
    grid-gap: 16px; /* 각 항목 간의 간격 */
    grid-row-gap: 32px;
    padding: 0px 0px 0px 56px;

    @media (max-width: ${"768px"}) {
      grid-template-columns: repeat(2, 1fr); /* 2개의 열 */
    }
  `,

  KeywordItem: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `,

  Rank: styled.span<StyleProps>`
    width: 32px;
    text-align: center;
    font-style: italic;
    font-size: 24px;
    font-weight: 700;
    ${({ $styles }) => $styles && { ...$styles }};
  `,

  KeywordText: styled.span`
    font-size: 18px;
    font-weight: 400;
    color: #282828;
  `,

  PcVisible: styled.div`
    display: block;

    @media (max-width: ${"768px"}) {
      display: none;
    }
  `,

  MobileVisible: styled.div`
    display: none;

    @media (max-width: ${"768px"}) {
      display: block;
    }
  `,

  FlexRowLayout: styled.div<StyleProps>`
    display: flex;

    ${({ $styles }) => $styles && { ...$styles }};
  `,

  FlexColumnLayout: styled.div<StyleProps>`
    display: flex;
    flex-direction: column;

    ${({ $styles }) => $styles && { ...$styles }};
  `,
};
