import Moment from "moment";
import styled from "styled-components";

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  RegionListData,
  filterValues,
  recommendKeywords,
  relatedKeywords,
} from "utils/filterUtils";
import { numberFormat } from "utils/fomatter";

import { Item } from "models/searchModels/SearchModels";

import ARROW_DOWN_GRAY from "../../assets/images/arrow_down_gray_24.svg";
import CHANNEL_IMAGE from "../../assets/images/channel_image.png";
import ARROW_DOWN_BUTTON from "../../assets/images/arrow_down_button.svg";
import CONTACT_IMAGE from "../../assets/images/contact_background.png";

import KeywordList from "components/common/keywordList/KeywordList";
import LineChart from "components/common/graph/LineChart";
import DonutChart from "components/common/graph/DonutChart";
import RegionList from "components/common/RegionList/RegionList";

const DetailPage = () => {
  const location = useLocation();
  const { id } = useParams();

  const [itemDetailData, setItemDetailData] = useState<Item | null>(null);

  const getDetailData = () => {
    const itemData = filterValues.items.filter(
      (item) => item.id === "TtlPkNyEO00"
    )[0];
    setItemDetailData(itemData);
  };

  const handleSettingValueBasedOnQuery = (params: URLSearchParams) => {};

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    handleSettingValueBasedOnQuery(params);
  }, [location]);

  useEffect(() => {
    if (id) {
      getDetailData();
    }
  }, [id]);

  if (!itemDetailData) {
    return null;
  }

  return (
    <Styles.Container>
      <Styles.MainSection>
        <Styles.ThumbnailWrap>
          <Styles.ThumbnailImage
            src={itemDetailData.thumbnails.standard.url}
            alt="thumbnail"
          />
        </Styles.ThumbnailWrap>

        <Styles.InfoWrap>
          <Styles.Text
            $styles={{
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "21.6px",
              color: "#82838E",
              marginBottom: "8px",
            }}
          >
            {Moment(itemDetailData.pdate).format("YYYY-MM-DD")}
          </Styles.Text>

          <Styles.Text
            $styles={{
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "28.8px",
              color: "#202123",
              marginBottom: "32px",
            }}
          >
            {itemDetailData.title}
          </Styles.Text>

          <Styles.KeywordBox>
            <Styles.Text
              $styles={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "21.6px",
                color: "#82838E",
                marginBottom: "8px",
              }}
            >
              키워드
            </Styles.Text>

            <Styles.KeywordWrap>
              {Array.isArray(itemDetailData.tags) &&
                itemDetailData.tags.map((item: string, idx: number) => {
                  return (
                    <Styles.KeywordBadge key={idx}>{item}</Styles.KeywordBadge>
                  );
                })}
            </Styles.KeywordWrap>
          </Styles.KeywordBox>
        </Styles.InfoWrap>
      </Styles.MainSection>

      <Styles.DashboardSection>
        {/* 검색데이터 정보 */}
        <Styles.DashboardContent>
          <Styles.DashboardInfo
            $mobileStyles={{
              borderRadius: "0px",
              padding: "12px",
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
            }}
          >
            <Styles.Text
              $styles={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#82838E",
                lineHeight: "19.2px",
              }}
              $mobileStyles={{
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "14.4px",
              }}
            >
              조회수
            </Styles.Text>

            <Styles.Text
              $styles={{
                fontSize: "28px",
                fontWeight: "700",
                color: "#202123",
                lineHeight: "33.61px",
                padding: "8px 0px 0px 0px",
              }}
              $mobileStyles={{
                textAlign: "center",
                fontSize: "14px",
                lineHeight: "16.8px",
              }}
            >
              {numberFormat(itemDetailData.viewcounts)}
            </Styles.Text>
          </Styles.DashboardInfo>

          <Styles.DashboardInfo
            $mobileStyles={{
              borderRadius: "0px",
              padding: "12px",
              borderRight: "none",
              borderLeft: "none",
            }}
          >
            <Styles.Text
              $styles={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#82838E",
                lineHeight: "19.2px",
              }}
              $mobileStyles={{
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "14.4px",
              }}
            >
              댓글
            </Styles.Text>

            <Styles.Text
              $styles={{
                fontSize: "28px",
                fontWeight: "700",
                color: "#202123",
                lineHeight: "33.61px",
                padding: "8px 0px 0px 0px",
              }}
              $mobileStyles={{
                textAlign: "center",
                fontSize: "14px",
                lineHeight: "16.8px",
              }}
            >
              {numberFormat(itemDetailData.commentscounts)}
            </Styles.Text>
          </Styles.DashboardInfo>

          <Styles.DashboardInfo
            $mobileStyles={{
              borderRadius: "0px",
              padding: "12px",
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
          >
            <Styles.Text
              $styles={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#82838E",
                lineHeight: "19.2px",
              }}
              $mobileStyles={{
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "14.4px",
              }}
            >
              좋아요
            </Styles.Text>

            <Styles.Text
              $styles={{
                fontSize: "28px",
                fontWeight: "700",
                color: "#202123",
                lineHeight: "33.61px",
                padding: "8px 0px 0px 0px",
              }}
              $mobileStyles={{
                textAlign: "center",
                fontSize: "14px",
                lineHeight: "16.8px",
              }}
            >
              {numberFormat(itemDetailData.likecounts)}
            </Styles.Text>
          </Styles.DashboardInfo>
        </Styles.DashboardContent>

        {/* 채널 정보 */}
        <Styles.DashBoardChannel>
          <Styles.DashboardInfo $mobileStyles={{ padding: "0px" }}>
            <Styles.ChannelTitle>
              <Styles.ChannelImage src={CHANNEL_IMAGE} alt="pickus" />

              <Styles.Text
                $styles={{
                  fontSize: "28px",
                  fontWeight: "700",
                  color: "#202123",
                  lineHeight: "33.61px",
                }}
              >
                NewJeans
              </Styles.Text>
            </Styles.ChannelTitle>

            <Styles.ChannelInfo>
              <Styles.ChannelInfoItem
                $mobileStyles={{
                  borderRight: "none",
                  borderLeft: "none",
                  borderBottom: "none",
                }}
              >
                <Styles.Text
                  $styles={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#82838E",
                    lineHeight: "19.2px",
                  }}
                  $mobileStyles={{
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "14.4px",
                  }}
                >
                  구독자
                </Styles.Text>

                <Styles.Text
                  $styles={{
                    fontSize: "28px",
                    fontWeight: "700",
                    color: "#202123",
                    lineHeight: "33.61px",
                    padding: "8px 0px 0px 0px",
                  }}
                  $mobileStyles={{
                    textAlign: "center",
                    fontSize: "14px",
                    lineHeight: "16.8px",
                  }}
                >
                  {numberFormat(800000)}명
                </Styles.Text>
              </Styles.ChannelInfoItem>

              <Styles.ChannelInfoItem
                $mobileStyles={{
                  borderBottom: "none",
                  borderRight: "none",
                }}
              >
                <Styles.Text
                  $styles={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#82838E",
                    lineHeight: "19.2px",
                  }}
                  $mobileStyles={{
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "14.4px",
                  }}
                >
                  조회수
                </Styles.Text>

                <Styles.Text
                  $styles={{
                    fontSize: "28px",
                    fontWeight: "700",
                    color: "#202123",
                    lineHeight: "33.61px",
                    padding: "8px 0px 0px 0px",
                  }}
                  $mobileStyles={{
                    textAlign: "center",
                    fontSize: "14px",
                    lineHeight: "16.8px",
                  }}
                >
                  {numberFormat(3900699241)}
                </Styles.Text>
              </Styles.ChannelInfoItem>

              <Styles.ChannelInfoItem
                $mobileStyles={{
                  borderBottom: "none",
                  borderRight: "none",
                  borderLeft: "none",
                }}
              >
                <Styles.Text
                  $styles={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#82838E",
                    lineHeight: "19.2px",
                  }}
                  $mobileStyles={{
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "14.4px",
                  }}
                >
                  영상
                </Styles.Text>

                <Styles.Text
                  $styles={{
                    fontSize: "28px",
                    fontWeight: "700",
                    color: "#202123",
                    lineHeight: "33.61px",
                    padding: "8px 0px 0px 0px",
                  }}
                  $mobileStyles={{
                    textAlign: "center",
                    fontSize: "14px",
                    lineHeight: "16.8px",
                  }}
                >
                  {numberFormat(234)}개
                </Styles.Text>
              </Styles.ChannelInfoItem>

              <Styles.ChannelInfoItem
                $borderRightNone={true}
                $mobileStyles={{
                  borderBottom: "none",
                  borderRight: "none",
                }}
              >
                <Styles.Text
                  $styles={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#82838E",
                    lineHeight: "19.2px",
                  }}
                  $mobileStyles={{
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "14.4px",
                  }}
                >
                  카테고리
                </Styles.Text>

                <Styles.Text
                  $styles={{
                    fontSize: "28px",
                    fontWeight: "700",
                    color: "#202123",
                    lineHeight: "33.61px",
                    padding: "8px 0px 0px 0px",
                  }}
                  $mobileStyles={{
                    textAlign: "center",
                    fontSize: "14px",
                    lineHeight: "16.8px",
                  }}
                >
                  음악
                </Styles.Text>
              </Styles.ChannelInfoItem>
            </Styles.ChannelInfo>
          </Styles.DashboardInfo>
        </Styles.DashBoardChannel>

        {/* 키워드 리스트 */}
        <Styles.DashBoardKeywordWrap>
          <Styles.DashboardInfo>
            <KeywordList
              title={"추천 키워드 리스트"}
              list={recommendKeywords}
            />
          </Styles.DashboardInfo>

          <Styles.DashboardInfo>
            <KeywordList
              title={"검색 키워드 연관 키워드 리스트"}
              list={relatedKeywords}
            />
          </Styles.DashboardInfo>
        </Styles.DashBoardKeywordWrap>

        {/* 키워드 그래프 */}
        <Styles.DashBoardKeywordGraph>
          <Styles.DashboardInfo $styles={{ padding: "0px" }}>
            <LineChart />
          </Styles.DashboardInfo>
        </Styles.DashBoardKeywordGraph>

        {/* 문의하기 배너 */}
        <Styles.DashBoardAsk>
          <Styles.DashboardInfo
            $styles={{
              position: "relative",
              backgroundImage: `url(${CONTACT_IMAGE})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            $mobileStyles={{ padding: "24px 16px" }}
          >
            {/* <Styles.ContactImage src={CONTACT_IMAGE} alt="pickus" /> */}
            <Styles.FlexRowLayout
              $styles={{
                alignItems: "center",
                justifyContent: "center",
                gap: "32px",
              }}
              $mobileStyles={{
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Styles.Text
                $styles={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#202123",
                  lineHeight: "28px",
                }}
              >
                검색량을 늘리고 싶나요?
              </Styles.Text>

              <Styles.ContactButton>문의하기</Styles.ContactButton>
            </Styles.FlexRowLayout>
          </Styles.DashboardInfo>
        </Styles.DashBoardAsk>

        {/* 지역 비교분석 표 */}
        <Styles.DashBoardAnalysis>
          <Styles.DashboardInfo>
            <Styles.Text
              $styles={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#202123",
                lineHeight: "24px",
              }}
            >
              지역 비교분석 표
            </Styles.Text>

            <Styles.FlexRowLayout
              $styles={{
                alignItems: "center",
                gap: "6px",
                margin: "8px 0px 0px 0px",
              }}
            >
              <Styles.Text
                $styles={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#82838e",
                  lineHeight: "19.2px",
                }}
              >
                키워드
              </Styles.Text>

              <Styles.FlexRowLayout
                $styles={{ alignItems: "center", gap: "4px" }}
              >
                <Styles.Dot
                  $styles={{
                    width: "12px",
                    height: "12px",
                    background: "#F68445",
                    borderRadius: "100px",
                  }}
                ></Styles.Dot>

                <Styles.Text
                  $styles={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#202123",
                    lineHeight: "19.2px",
                  }}
                >
                  뉴진스에 대한 관심도
                </Styles.Text>
              </Styles.FlexRowLayout>

              <Styles.ArrowDownButton src={ARROW_DOWN_BUTTON} alt="pickus" />
            </Styles.FlexRowLayout>

            <Styles.FlexRowLayout
              $styles={{ gap: "48px", justifyContent: "space-between" }}
              $mobileStyles={{
                overflowX: "auto",
              }}
            >
              <Styles.FlexRowLayout $styles={{ gap: "48px" }}>
                <Styles.FlexColumnLayout $styles={{ gap: "8px" }}>
                  {RegionListData.map((item) => {
                    if (item.id < 9) {
                      return (
                        <RegionList
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          segments={item.segments}
                        />
                      );
                    }
                  })}
                </Styles.FlexColumnLayout>
                <Styles.FlexColumnLayout $styles={{ gap: "8px" }}>
                  {RegionListData.map((item) => {
                    if (item.id > 8) {
                      return (
                        <RegionList
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          segments={item.segments}
                        />
                      );
                    }
                  })}
                </Styles.FlexColumnLayout>
              </Styles.FlexRowLayout>

              <Styles.DonutChartWrap>
                <DonutChart />
              </Styles.DonutChartWrap>
            </Styles.FlexRowLayout>
          </Styles.DashboardInfo>
        </Styles.DashBoardAnalysis>

        {/* 관련 영상 */}
        <Styles.DashBoardRelated>
          <Styles.Text
            $styles={{
              fontSize: "20px",
              fontWeight: "700",
              color: "#202123",
              lineHeight: "24px",
              padding: "12px 16px 12px 16px",
            }}
          >
            관련 영상
          </Styles.Text>
          <Styles.FlexColumnLayout $styles={{ gap: "16px" }}>
            {filterValues.items.slice(0, 4).map((item) => {
              return (
                <Styles.DashboardInfo
                  $mobileStyles={{ padding: "0px", borderRadius: "0px" }}
                >
                  <Styles.ContentItem key={item.id}>
                    <Styles.FlexColumnLayout
                      $styles={{ width: "100%", flex: 1 }}
                      $mobileStyles={{ padding: "16px 16px 0px 16px" }}
                    >
                      <Styles.FlexRowLayout $styles={{ gap: "8px" }}>
                        <Styles.ContentThumbnailImage
                          src={item.thumbnails.default.url}
                          alt="pickus"
                        />

                        <Styles.ContentInfoWrap>
                          <Styles.Text
                            $styles={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "#82838E",
                              lineHeight: "15.6px",
                            }}
                          >
                            {Moment(item.pdate).format("YYYY-MM-DD")}
                          </Styles.Text>

                          <Styles.Text
                            $styles={{
                              width: "100%",
                              maxWidth: "379px",
                              margin: "2px 0px 0px 0px",
                              overflow: "hidden",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,
                              textOverflow: "ellipsis",
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "#232020",
                              lineHeight: "18px",
                              wordBreak: "break-word",
                            }}
                            $mobileStyles={{ maxWidth: "100%" }}
                          >
                            {item.title}
                          </Styles.Text>

                          <Styles.PcVisible>
                            <Styles.CategoryBadgeWrap>
                              {Array.isArray(item.tags) &&
                                item.tags.map((item: string, idx: number) => {
                                  return (
                                    <Styles.CategoryBadge key={idx}>
                                      {item}
                                    </Styles.CategoryBadge>
                                  );
                                })}
                            </Styles.CategoryBadgeWrap>
                          </Styles.PcVisible>
                        </Styles.ContentInfoWrap>
                      </Styles.FlexRowLayout>

                      <Styles.MobileVisible>
                        <Styles.CategoryBadgeWrap>
                          {Array.isArray(item.tags) &&
                            item.tags.map((item: string, idx: number) => {
                              return (
                                <Styles.CategoryBadge key={idx}>
                                  {item}
                                </Styles.CategoryBadge>
                              );
                            })}
                        </Styles.CategoryBadgeWrap>
                      </Styles.MobileVisible>
                    </Styles.FlexColumnLayout>

                    <Styles.FlexRowLayout
                      style={{ flex: 1 }}
                      $mobileStyles={{
                        width: "100%",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Styles.ContentSummaryWrap>
                        <Styles.ContentSummaryItem>
                          <Styles.Text
                            $styles={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "#A1A2AE",
                              lineHeight: "15.6px",
                            }}
                          >
                            조회수
                          </Styles.Text>
                          <Styles.Text
                            $styles={{
                              fontSize: "15px",
                              fontWeight: "700",
                              color: "#282828",
                              lineHeight: "18px",
                            }}
                          >
                            {numberFormat(item.viewcounts)}
                          </Styles.Text>
                        </Styles.ContentSummaryItem>

                        <Styles.ContentSummaryItem>
                          <Styles.Text
                            $styles={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "#A1A2AE",
                              lineHeight: "15.6px",
                            }}
                          >
                            일 평균 조회수
                          </Styles.Text>
                          <Styles.Text
                            $styles={{
                              fontSize: "15px",
                              fontWeight: "700",
                              color: "#282828",
                              lineHeight: "18px",
                            }}
                          >
                            {numberFormat(item.viewcounts)}
                          </Styles.Text>
                        </Styles.ContentSummaryItem>

                        <Styles.ContentSummaryItem>
                          <Styles.Text
                            $styles={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "#A1A2AE",
                              lineHeight: "15.6px",
                            }}
                          >
                            좋아요
                          </Styles.Text>
                          <Styles.Text
                            $styles={{
                              fontSize: "15px",
                              fontWeight: "700",
                              color: "#282828",
                              lineHeight: "18px",
                            }}
                          >
                            {numberFormat(item.likecounts)}
                          </Styles.Text>
                        </Styles.ContentSummaryItem>

                        <Styles.ContentSummaryItem $borderRightNone={true}>
                          <Styles.Text
                            $styles={{
                              fontSize: "13px",
                              fontWeight: "600",
                              color: "#A1A2AE",
                              lineHeight: "15.6px",
                            }}
                          >
                            댓글
                          </Styles.Text>
                          <Styles.Text
                            $styles={{
                              fontSize: "15px",
                              fontWeight: "700",
                              color: "#282828",
                              lineHeight: "18px",
                            }}
                          >
                            {numberFormat(item.commentscounts)}
                          </Styles.Text>
                        </Styles.ContentSummaryItem>
                      </Styles.ContentSummaryWrap>
                    </Styles.FlexRowLayout>
                  </Styles.ContentItem>
                </Styles.DashboardInfo>
              );
            })}
          </Styles.FlexColumnLayout>

          {filterValues.items.length > 0 && (
            <Styles.InfinityScrollSection>
              <Styles.InfinityScrollButton>
                <Styles.Text
                  $styles={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#82838E",
                    lineHeight: "16.8px",
                  }}
                >
                  더 보기
                </Styles.Text>

                <Styles.ArrowIcon
                  src={ARROW_DOWN_GRAY}
                  alt="pickus"
                  onClick={() => null}
                />
              </Styles.InfinityScrollButton>
              {/* {isLoading && (
          <Styles.InfiniScrollLoading>
            <Loading />
          </Styles.InfiniScrollLoading>
        )} */}
            </Styles.InfinityScrollSection>
          )}
        </Styles.DashBoardRelated>
      </Styles.DashboardSection>
    </Styles.Container>
  );
};

export default DetailPage;

interface StyleProps {
  $styles?: React.CSSProperties | null;
  $mobileStyles?: React.CSSProperties | null;
  $borderRightNone?: boolean | null;
  $active?: boolean | null;
}

const Styles = {
  Container: styled.div`
    font-family: "Pretendard";
  `,

  MainSection: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    gap: 48px;
    padding: 48px 0px 48px 0px;

    @media (max-width: ${"768px"}) {
      max-width: 100%;
      flex-direction: column;
    }
  `,

  ThumbnailWrap: styled.div`
    width: 100%;
    max-width: 576px;
    height: 323px;
    flex: 1;

    @media (max-width: ${"768px"}) {
      max-width: 100%;
      height: 210px;
      flex: none;
    }
  `,

  ThumbnailImage: styled.img`
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
    background-color: aliceblue;

    @media (max-width: ${"768px"}) {
      border-radius: 0px;
      object-fit: contain;
    }
  `,

  InfoWrap: styled.div`
    width: 100%;
    max-width: 576px;
    flex: 1;

    @media (max-width: ${"768px"}) {
      padding: 0px 16px 0px 16px;
    }
  `,

  KeywordBox: styled.div``,

  KeywordWrap: styled.div`
    display: flex;
    gap: 8px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  `,

  KeywordBadge: styled.div`
    width: max-content;
    white-space: nowrap;
    padding: 3px 8px 3px 8px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    color: #4b4c57;
    background: #eaeef5;
  `,

  DashboardSection: styled.div`
    margin: auto;
    background-color: #fafbfc;
    height: 100%;
    border-top: 1px solid #e7ebf3;
    padding: 0px 0px 48px 0px;

    @media (max-width: ${"768px"}) {
      width: 100%;
      max-width: 100%;
    }
  `,

  DashboardContent: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 24px 0px 32px 0px;
    display: flex;
    gap: 32px;

    @media (max-width: ${"768px"}) {
      max-width: 100%;
      gap: 0px;
      padding: 16px;
    }
  `,

  ContactImage: styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,

  ContactButton: styled.button`
    padding: 12px 20px;
    background-color: #ff9839;
    color: #ffffff;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border: none;
    border-radius: 8px;
  `,

  DashBoardChannel: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0px 0px 32px 0px;

    @media (max-width: ${"768px"}) {
      max-width: 100%;
      gap: 0px;
      padding: 16px;
    }
  `,

  ChannelTitle: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width: ${"768px"}) {
      padding: 16px;
    }
  `,

  ChannelImage: styled.img`
    width: 40px;
    height: 40px;
  `,

  ChannelInfo: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    margin: 24px 0px 0px 0px;

    @media (max-width: ${"768px"}) {
      display: grid;
      grid-template-columns: 1fr 1fr; /* 두 개의 동일한 너비 컬럼 */
      grid-template-rows: 1fr 1fr;
      gap: 0px;
      margin: 0px;
    }
  `,

  ChannelInfoItem: styled.div<StyleProps>`
    border-right: ${({ $borderRightNone }) =>
      $borderRightNone ? "none" : "1px solid #E7EBF3"};
    flex: 1;

    @media (max-width: ${"768px"}) {
      border: 1px solid #e7ebf3;
      padding: 12px;
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  DashBoardKeywordWrap: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0px 0px 32px 0px;
    display: flex;
    gap: 32px;

    @media (max-width: ${"768px"}) {
      flex-direction: column;
      max-width: 100%;
      row-gap: 16px;
      padding: 16px;
    }
  `,

  DashBoardKeywordGraph: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0px 0px 32px 0px;

    @media (max-width: ${"768px"}) {
      max-width: 100%;
    }
  `,

  DashBoardAsk: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0px 0px 32px 0px;

    @media (max-width: ${"768px"}) {
      max-width: 100%;
    }
  `,

  DashBoardAnalysis: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0px 0px 32px 0px;

    @media (max-width: ${"768px"}) {
      max-width: 100%;
    }
  `,

  DashBoardRelated: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;

    @media (max-width: ${"768px"}) {
      max-width: 100%;
    }
  `,

  DashboardInfo: styled.div<StyleProps>`
    width: 100%;
    padding: 28px;
    border-radius: 12px;
    border: 1px solid #e7ebf3;
    background: #ffffff;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  GraphBox: styled.div``,

  DonutChartWrap: styled.div`
    width: 100%;
    max-width: 448px;
    border-radius: 8px;
    border: 1px solid #eaeef5;
    padding: 8px;

    @media (max-width: ${"768px"}) {
      display: none;
    }
  `,

  InfinityScrollSection: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 16px 0px 0px 0px;

    @media (max-width: ${"768px"}) {
      max-width: 100%;
    }
  `,

  InfinityScrollButton: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 12px 16px 12px 20px;
    border-radius: 8px;
    background: #eaeef5;
    cursor: pointer;
  `,

  Dot: styled.div<StyleProps>`
    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  ContentItem: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    /* padding: 16px; */
    border-radius: 12px;

    @media (max-width: ${"768px"}) {
      /* padding: 0px; */
      flex-direction: column;
      border: none;
      border-bottom: 1px solid #e7ebf3;
      border-radius: 0;
    }
  `,

  ContentInfoWrap: styled.div`
    width: 100%;
    max-width: 379px;

    @media (max-width: ${"768px"}) {
      width: max-content;
    }
  `,

  ContentThumbnailImage: styled.img`
    width: 168px;
    height: 94px;
    border-radius: 5px;
    object-fit: cover;
    background-color: #dfdfdf;

    @media (max-width: ${"768px"}) {
      width: 88px;
      min-width: 88px;
      height: 54px;
    }
  `,

  CategoryBadgeWrap: styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0px 0px 0px;
    gap: 8px;
    overflow: auto;
    cursor: grab;

    &::-webkit-scrollbar {
      display: none;
    }

    &:active {
      cursor: grabbing;
    }

    @media (max-width: ${"768px"}) {
      width: 100%;
    }
  `,

  CategoryBadge: styled.div`
    width: max-content;
    white-space: nowrap;
    padding: 3px 8px 3px 8px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    color: #4b4c57;
    background: #eaeef5;
  `,

  ContentSummaryWrap: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    @media (max-width: ${"768px"}) {
      padding: 8px 0px 8px 0px;
      background-color: #fafbfc;
    }
  `,

  ContentSummaryItem: styled.div<StyleProps>`
    width: 100%;
    max-width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;

    @media (max-width: ${"768px"}) {
      border-right: ${({ $borderRightNone }) =>
        $borderRightNone ? "none" : "1px solid #e7ebf3"};
    }
  `,

  ArrowIcon: styled.img`
    cursor: pointer;
  `,

  ArrowDownButton: styled.img`
    cursor: pointer;
  `,

  Text: styled.div<StyleProps>`
    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  PcVisible: styled.div<StyleProps>`
    display: block;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      display: none;
    }
  `,

  MobileVisible: styled.div`
    display: none;

    @media (max-width: ${"768px"}) {
      display: block;
    }
  `,

  FlexRowLayout: styled.div<StyleProps>`
    display: flex;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  FlexColumnLayout: styled.div<StyleProps>`
    display: flex;
    flex-direction: column;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,
};
