import React from "react";
import { Chart } from "react-google-charts";

const data = [
  ["Category", "Percentage"],
  ["뉴진스", 20],
  ["유진스", 45.5],
  ["뉴진스님", 34.5],
];

const options = {
  title: "경기도",
  pieHole: 0.6,
  colors: ["#F68445", "#31EB87", "#458BF6"],
  legend: {
    position: "bottom",
    alignment: "center",
    textStyle: {
      fontSize: 12,
    },
  },
  chartArea: { width: "392px", height: "200px" },
  pieSliceText: "none",
};

const DonutChart = () => {
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
};

export default DonutChart;
