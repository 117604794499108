import Moment from "moment";
import styled from "styled-components";
import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import FILTER_ICON from "../../assets/images/filter_icon.svg";
import FILTER_ICON_DEFAULT_MOBILE from "../../assets/images/filter_icon_default_mobile.svg";
import FILTER_ICON_ON_MOBILE from "../../assets/images/filter_icon_on_mobile.svg";
import ARROW_UP from "../../assets/images/arrow_up_24.svg";
import ARROW_DOWN from "../../assets/images/arrow_down_24.svg";
import ARROW_DOWN_GRAY from "../../assets/images/arrow_down_gray_24.svg";
import ARROW_DOWN_BORDER from "../../assets/images/arrow_down_border_24.svg";
import EMPTY_IMAGE from "../../assets/images/empty_image.png";
import SEARCH from "../../assets/images/search_icon_24.svg";
import CLOSE_ICON_32 from "../../assets/images/close_icon_black_32.svg";
import LOADING_SPINNER from "../../assets/images/loading_spinner.gif";

import {
  categoryFilterList,
  countryFilterList,
  durationFilterList,
  filterValues,
  genreFilterList,
  sortFilterList,
  uploadTimeFilterList,
  videoTypeFilterList,
  viewFilterList,
} from "utils/filterUtils";
import { numberFormat } from "utils/fomatter";
import { useWindowSize } from "hooks/useWindowSize";
import { FilterValues } from "models/searchModels/SearchModels";
import { fetchSearchResults } from "services/searchApi";

interface StyleProps {
  $styles?: React.CSSProperties | null;
  $mobileStyles?: React.CSSProperties | null;
  $borderRightNone?: boolean | null;
}

interface SortFilterItem {
  id: number;
  label: string;
  value: string;
}

enum KeyCode {
  Enter = "Enter",
}

enum EventType {
  Click = "click",
  KeyDown = "keydown",
}

const SearchPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);

  const { windowWidth, windowHeight } = useWindowSize();

  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  // 필터 오픈 상태
  const [isExpandFilter, setIsExpandFilter] = useState<boolean>(false);
  const [isShowSortFilter, setIsShowSortFilter] = useState<boolean>(false);

  // 검색어
  const [searchQuery, setSearchQuery] = useState<string>("");

  // 필터 값
  const [viewFilterQuery, setViewFilterQuery] = useState<number | null>(null);
  const [videoFilterQuery, setVideoFilterQuery] = useState<number | null>(null);
  const [timeFilterQuery, setTimeFilterQuery] = useState<number | null>(null);
  const [dateFilterQuery, setDateFilterQuery] = useState<number | null>(null);
  const [categoryFilterQuery, setCategoryFilterQuery] = useState<number | null>(
    null
  );
  const [channelFilterQuery, setChannelFilterQuery] = useState<string | null>(
    null
  );
  const [nationalFilterQuery, setNationalFilterQuery] = useState<number | null>(
    null
  );
  const [keywordSearchValue, setKeywordSearchValue] = useState<string>("");

  // 선택된 필터
  const [selectedFilters, setSelectedFilters] = useState<
    { id: number; label: string }[]
  >([]);

  // 정렬 값
  const [sortFilterQuery, setSortFilterQuery] = useState<SortFilterItem>({
    id: 0,
    label: "관련도순",
    value: "relevance",
  });

  // 검색 데이터
  const [searchData, setSearchData] = useState<FilterValues | null>(null);

  const onExpandFilter = () => {
    setIsExpandFilter(!isExpandFilter);
  };

  const onShowSortFilter = () => {
    setIsShowSortFilter(!isShowSortFilter);
  };

  const onSort = (item: SortFilterItem) => {
    navigate(
      `/search?q=${encodeURIComponent(searchQuery.trim())}&order=${item.value}`,
      {
        state: { noScroll: true },
      }
    );
  };

  const onFetchMore = () => {
    const searchParams = new URLSearchParams(location.search);
    const currentNum = Number(searchParams.get("num") || 11);
    searchParams.set("num", (currentNum + 10).toString());

    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleFilterClick = (type: string, value: number | string) => {
    switch (type) {
      case "view":
        setViewFilterQuery(
          value === viewFilterQuery ? null : (value as number)
        );

        break;
      case "video":
        setVideoFilterQuery(
          value === videoFilterQuery ? null : (value as number)
        );
        break;
      case "time":
        setTimeFilterQuery(
          value === timeFilterQuery ? null : (value as number)
        );
        break;
      case "date":
        setDateFilterQuery(
          value === dateFilterQuery ? null : (value as number)
        );
        break;
      case "category":
        setCategoryFilterQuery(
          value === categoryFilterQuery ? null : (value as number)
        );
        break;
      case "channel":
        setChannelFilterQuery(
          value === channelFilterQuery ? null : (value as string)
        );
        break;
      case "national":
        setNationalFilterQuery(
          value === nationalFilterQuery ? null : (value as number)
        );
        break;
      default:
        break;
    }
  };

  const handleSearch = (
    e: KeyboardEvent<HTMLInputElement> | MouseEvent<HTMLImageElement>
  ): void => {
    if (("key" in e && e.key === KeyCode.Enter) || e.type === EventType.Click) {
      if (searchValue.trim()) {
        navigate(
          `/search?q=${encodeURIComponent(
            encodeURIComponent(searchValue.trim())
          )}`,
          {
            state: { noScroll: true },
          }
        );
      }
    }
  };

  const handleSearchKeyword = (
    e: KeyboardEvent<HTMLInputElement> | MouseEvent<HTMLImageElement>
  ): void => {
    if (("key" in e && e.key === KeyCode.Enter) || e.type === EventType.Click) {
      if (keywordSearchValue.trim()) {
      }
    }
  };
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  const onNavigate = (id: string) => {
    navigate(`/search/view/${encodeURIComponent(id)}`);
  };

  const handleKeywordSearchChange = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    setKeywordSearchValue(e.target.value);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    const startX = e.clientX;
    const scrollLeft = scrollRef.current?.scrollLeft || 0;

    const handleMouseMove = (moveEvent: WindowEventMap["mousemove"]) => {
      if (scrollRef.current) {
        const x = moveEvent.clientX - startX;
        scrollRef.current.scrollLeft = scrollLeft - x;
      }
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleSettingValueBasedOnQuery = (params: any) => {
    // 검색어
    setSearchQuery(decodeURI(params.q));
    // 정렬값
    const findSortFilter = sortFilterList.filter(
      (item) => item.value === params.order
    );
    setSortFilterQuery(findSortFilter[0] || sortFilterList[0]);
  };

  useEffect(() => {
    const isMobile = windowWidth && windowWidth < 768;
    if (isExpandFilter && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isExpandFilter, windowWidth]);

  const getSearchResults = async (params: any) => {
    setIsLoading(true);
    try {
      const data = await fetchSearchResults(params);
      setSearchData(data);
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const keywordValue: string | null = queryParams.get("q");
    const sortValue: string | null = queryParams.get("order");
    const dataCountValue: string | null = queryParams.get("num");
    const pageTokenValue: string | null = queryParams.get("pagetoken");
    const filterValue: string | null = queryParams.get("filter");

    const params = {
      q: keywordValue || "",
      num: dataCountValue || 11,
      order: sortValue || "relevance",
      pageToken: pageTokenValue || "",
      filter: filterValue || "",
    };

    handleSettingValueBasedOnQuery(params);

    getSearchResults(params);
  }, [location]);

  return (
    <Styles.Search>
      <Styles.SearchBannerSection>
        <Styles.SearchBanner>
          <Styles.Text
            $styles={{
              fontSize: "32px",
              fontWeight: "600",
              color: "#282828",
              lineHeight: "44px",
            }}
            $mobileStyles={{ fontSize: "18px", lineHeight: "28px" }}
          >
            전세계 유튜브 영상을
          </Styles.Text>

          <Styles.FlexRowLayout
            $styles={{ gap: "8px" }}
            $mobileStyles={{ gap: "4px" }}
          >
            <Styles.Text
              $styles={{
                fontSize: "32px",
                fontWeight: "600",
                color: "#FF9839",
                lineHeight: "44px",
              }}
              $mobileStyles={{ fontSize: "18px", lineHeight: "28px" }}
            >
              pickus
            </Styles.Text>
            <Styles.Text
              $styles={{
                fontSize: "32px",
                fontWeight: "600",
                color: "#282828",
                lineHeight: "44px",
              }}
              $mobileStyles={{ fontSize: "18px", lineHeight: "28px" }}
            >
              필터로 검색하고 분석하세요.
            </Styles.Text>
          </Styles.FlexRowLayout>
        </Styles.SearchBanner>
      </Styles.SearchBannerSection>

      <Styles.FilterSection $isBorderBottomNone={isExpandFilter}>
        <Styles.FilterHeader>
          <Styles.PcVisible>
            <Styles.FilterButton>
              <Styles.FlexRowLayout
                $styles={{ alignItems: "center", gap: "4px" }}
              >
                <img src={FILTER_ICON} alt="pickus" />
                <Styles.Text
                  $styles={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#232020",
                    lineHeight: "19.2px",
                  }}
                >
                  필터 {isExpandFilter ? "접기" : "열기"}
                </Styles.Text>
              </Styles.FlexRowLayout>

              <Styles.ArrowIcon
                src={isExpandFilter ? ARROW_UP : ARROW_DOWN}
                alt="pickus"
                onClick={() => onExpandFilter()}
              />
            </Styles.FilterButton>
          </Styles.PcVisible>

          <Styles.MobileVisible>
            <Styles.FlexRowLayout
              $styles={{
                gap: "8px",
                padding: "8px 14px 8px 14px",
                justifyContent: "space-between",
              }}
            >
              <Styles.SearchInputWrapper>
                <Styles.FlexRowLayout
                  $styles={{
                    width: "100%",
                    gap: "8px",
                  }}
                >
                  <Styles.SearchIcon
                    src={SEARCH}
                    alt="Search"
                    onClick={handleSearch}
                  />
                  <Styles.SearchInput
                    type="search"
                    value={searchValue}
                    onChange={handleSearchChange}
                    onKeyDown={handleSearch}
                    placeholder="키워드를 입력해 주세요"
                  />
                </Styles.FlexRowLayout>
              </Styles.SearchInputWrapper>

              <img
                src={
                  selectedFilters.length > 0
                    ? FILTER_ICON_ON_MOBILE
                    : FILTER_ICON_DEFAULT_MOBILE
                }
                alt="pickus"
                onClick={() => onExpandFilter()}
              />
            </Styles.FlexRowLayout>
          </Styles.MobileVisible>
          <Styles.SelectedFilter></Styles.SelectedFilter>
        </Styles.FilterHeader>

        {isExpandFilter && (
          <Styles.FilterContentWrap>
            <Styles.MobileVisible>
              <Styles.FlexRowLayout
                $styles={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "12px 14px 8px 16px",
                }}
              >
                <Styles.Text>필터</Styles.Text>

                <img
                  src={CLOSE_ICON_32}
                  alt="pickus"
                  onClick={() => onExpandFilter()}
                />
              </Styles.FlexRowLayout>
            </Styles.MobileVisible>
            <Styles.FilterContent>
              <Styles.FilterItem $isBorderTopNone={true}>
                <Styles.FilterTitle>조회수</Styles.FilterTitle>

                <Styles.FilterValue>
                  {viewFilterList.map((item) => {
                    return (
                      <Styles.Chip
                        type="button"
                        key={item.id}
                        $active={item.id === viewFilterQuery}
                        onClick={() => handleFilterClick("view", item.id)}
                      >
                        {item.label}
                      </Styles.Chip>
                    );
                  })}
                </Styles.FilterValue>
              </Styles.FilterItem>

              <Styles.FilterItem>
                <Styles.FilterTitle>영상</Styles.FilterTitle>
                <Styles.FilterValue>
                  {videoTypeFilterList.map((item) => {
                    return (
                      <Styles.Chip
                        type="button"
                        key={item.id}
                        $active={item.id === videoFilterQuery}
                        onClick={() => handleFilterClick("video", item.id)}
                      >
                        {item.label}
                      </Styles.Chip>
                    );
                  })}
                </Styles.FilterValue>
              </Styles.FilterItem>

              <Styles.FilterItem>
                <Styles.FilterTitle>시간</Styles.FilterTitle>
                <Styles.FilterValue>
                  {durationFilterList.map((item) => {
                    return (
                      <Styles.Chip
                        type="button"
                        key={item.id}
                        $active={item.id === timeFilterQuery}
                        onClick={() => handleFilterClick("time", item.id)}
                      >
                        {item.label}
                      </Styles.Chip>
                    );
                  })}
                </Styles.FilterValue>
              </Styles.FilterItem>

              <Styles.FilterItem>
                <Styles.FilterTitle>등록일</Styles.FilterTitle>
                <Styles.FilterValue>
                  {uploadTimeFilterList.map((item) => {
                    return (
                      <Styles.Chip
                        type="button"
                        key={item.id}
                        $active={item.id === dateFilterQuery}
                        onClick={() => handleFilterClick("date", item.id)}
                      >
                        {item.label}
                      </Styles.Chip>
                    );
                  })}
                </Styles.FilterValue>
              </Styles.FilterItem>

              <Styles.FilterItem>
                <Styles.FilterTitle>카테고리</Styles.FilterTitle>
                <Styles.FilterValue>
                  {categoryFilterList.map((item) => {
                    return (
                      <Styles.Chip
                        type="button"
                        key={item.id}
                        $active={item.id === categoryFilterQuery}
                        onClick={() => handleFilterClick("category", item.id)}
                      >
                        {item.label}
                      </Styles.Chip>
                    );
                  })}
                </Styles.FilterValue>
              </Styles.FilterItem>

              <Styles.FilterItem>
                <Styles.FilterTitle>채널</Styles.FilterTitle>
                <Styles.FilterValue>
                  <Styles.ChannelSearchInputWrapper>
                    <Styles.FlexRowLayout
                      $styles={{ width: "100%", gap: "8px" }}
                    >
                      <Styles.SearchIcon
                        src={SEARCH}
                        alt="Search"
                        onClick={handleSearchKeyword}
                      />
                      <Styles.ChannelSearchInput
                        type="search"
                        value={keywordSearchValue}
                        onChange={handleKeywordSearchChange}
                        onKeyDown={handleSearchKeyword}
                        placeholder="채널을 입력해 주세요."
                      />
                    </Styles.FlexRowLayout>
                  </Styles.ChannelSearchInputWrapper>
                </Styles.FilterValue>
              </Styles.FilterItem>

              <Styles.FilterItem>
                <Styles.FilterTitle>국가</Styles.FilterTitle>
                <Styles.FilterValue>
                  {countryFilterList.map((item) => {
                    return (
                      <Styles.Chip
                        type="button"
                        key={item.id}
                        $active={item.id === nationalFilterQuery}
                        onClick={() => handleFilterClick("national", item.id)}
                      >
                        {item.label}
                      </Styles.Chip>
                    );
                  })}
                </Styles.FilterValue>
              </Styles.FilterItem>

              <Styles.SelectedFilterWrap>
                <Styles.PcVisible
                  $styles={{ width: "100%", maxWidth: "182px" }}
                >
                  <Styles.SelectedFilterTitle>
                    선택한 필터
                  </Styles.SelectedFilterTitle>
                </Styles.PcVisible>

                <Styles.SelectedFilterValue>
                  {/* {selectedFilterList.map((item) => {
                    return (
                      <Styles.Chip
                        type="button"
                        key={item.id}
                        $active={true}
                        onClick={() => handleSelectedFilterRemove(item.id)}
                      >
                        {item.label}
                      </Styles.Chip>
                    );
                  })} */}
                </Styles.SelectedFilterValue>
              </Styles.SelectedFilterWrap>
            </Styles.FilterContent>
          </Styles.FilterContentWrap>
        )}
      </Styles.FilterSection>

      <Styles.ContentSection>
        <Styles.ContentHeader>
          <Styles.Text
            $styles={{
              fontSize: "16px",
              fontWeight: "700",
              color: "#282828",
              lineHeight: "19.2px",
            }}
            $mobileStyles={{ display: "flex", alignItems: "center" }}
          >
            "{searchQuery}" 에 대한 검색 결과
          </Styles.Text>

          <Styles.SortFilter
            tabIndex={0}
            onBlur={() => setIsShowSortFilter(false)}
            onClick={() => onShowSortFilter()}
          >
            <Styles.Text
              $styles={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#82838E",
                lineHeight: "19.2px",
              }}
            >
              {sortFilterQuery.label}
            </Styles.Text>

            <Styles.BorderArrowDownIcon src={ARROW_DOWN_BORDER} alt="pickus" />

            {isShowSortFilter && (
              <Styles.SortFilterList>
                {sortFilterList.map((item) => {
                  return (
                    <Styles.SortFilterItem
                      key={item.id}
                      $active={item.id === sortFilterQuery.id}
                      onClick={() => onSort(item)}
                    >
                      {item.label}
                    </Styles.SortFilterItem>
                  );
                })}
              </Styles.SortFilterList>
            )}
          </Styles.SortFilter>
        </Styles.ContentHeader>

        <Styles.ContentWrap>
          {searchData?.items!?.length > 0 &&
            searchData?.items.map((item) => {
              return (
                <Styles.ContentItem key={item.id}>
                  <Styles.FlexColumnLayout $styles={{ width: "100%", flex: 1 }}>
                    <Styles.FlexRowLayout $styles={{ gap: "8px" }}>
                      <Styles.ThumbnailImage
                        src={item.thumbnails.default.url}
                        alt="pickus"
                      />

                      <Styles.ContentInfoWrap>
                        <Styles.Text
                          $styles={{
                            fontSize: "13px",
                            fontWeight: "600",
                            color: "#82838E",
                            lineHeight: "15.6px",
                          }}
                        >
                          {Moment(item.pdate).format("YYYY-MM-DD")}
                        </Styles.Text>

                        <Styles.Text
                          $styles={{
                            width: "100%",
                            maxWidth: "379px",
                            margin: "2px 0px 0px 0px",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            textOverflow: "ellipsis",
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "#232020",
                            lineHeight: "18px",
                            wordBreak: "break-word",
                          }}
                          $mobileStyles={{ maxWidth: "100%" }}
                        >
                          {item.title}
                        </Styles.Text>

                        <Styles.PcVisible>
                          <Styles.CategoryBadgeWrap
                            ref={scrollRef}
                            onMouseDown={handleMouseDown}
                          >
                            {Array.isArray(item.tags) &&
                              item.tags.map((item: string, idx: number) => {
                                return (
                                  <Styles.CategoryBadge key={idx}>
                                    {item}
                                  </Styles.CategoryBadge>
                                );
                              })}
                          </Styles.CategoryBadgeWrap>
                        </Styles.PcVisible>
                      </Styles.ContentInfoWrap>
                    </Styles.FlexRowLayout>

                    <Styles.MobileVisible>
                      <Styles.CategoryBadgeWrap
                        ref={scrollRef}
                        onMouseDown={handleMouseDown}
                      >
                        {Array.isArray(item.tags) &&
                          item.tags.map((item: string, idx: number) => {
                            return (
                              <Styles.CategoryBadge key={idx}>
                                {item}
                              </Styles.CategoryBadge>
                            );
                          })}
                      </Styles.CategoryBadgeWrap>
                    </Styles.MobileVisible>
                  </Styles.FlexColumnLayout>

                  <Styles.FlexRowLayout
                    style={{ flex: 1 }}
                    $mobileStyles={{
                      width: "100%",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Styles.ContentSummaryWrap>
                      <Styles.ContentSummaryItem>
                        <Styles.Text
                          $styles={{
                            fontSize: "13px",
                            fontWeight: "600",
                            color: "#A1A2AE",
                            lineHeight: "15.6px",
                          }}
                        >
                          조회수
                        </Styles.Text>
                        <Styles.Text
                          $styles={{
                            fontSize: "15px",
                            fontWeight: "700",
                            color: "#282828",
                            lineHeight: "18px",
                          }}
                        >
                          {numberFormat(item.viewcounts)}
                        </Styles.Text>
                      </Styles.ContentSummaryItem>

                      <Styles.ContentSummaryItem>
                        <Styles.Text
                          $styles={{
                            fontSize: "13px",
                            fontWeight: "600",
                            color: "#A1A2AE",
                            lineHeight: "15.6px",
                          }}
                        >
                          일 평균 조회수
                        </Styles.Text>
                        <Styles.Text
                          $styles={{
                            fontSize: "15px",
                            fontWeight: "700",
                            color: "#282828",
                            lineHeight: "18px",
                          }}
                        >
                          {numberFormat(item.viewcounts)}
                        </Styles.Text>
                      </Styles.ContentSummaryItem>

                      <Styles.ContentSummaryItem>
                        <Styles.Text
                          $styles={{
                            fontSize: "13px",
                            fontWeight: "600",
                            color: "#A1A2AE",
                            lineHeight: "15.6px",
                          }}
                        >
                          좋아요
                        </Styles.Text>
                        <Styles.Text
                          $styles={{
                            fontSize: "15px",
                            fontWeight: "700",
                            color: "#282828",
                            lineHeight: "18px",
                          }}
                        >
                          {numberFormat(item.likecounts)}
                        </Styles.Text>
                      </Styles.ContentSummaryItem>

                      <Styles.ContentSummaryItem $borderRightNone={true}>
                        <Styles.Text
                          $styles={{
                            fontSize: "13px",
                            fontWeight: "600",
                            color: "#A1A2AE",
                            lineHeight: "15.6px",
                          }}
                        >
                          댓글
                        </Styles.Text>
                        <Styles.Text
                          $styles={{
                            fontSize: "15px",
                            fontWeight: "700",
                            color: "#282828",
                            lineHeight: "18px",
                          }}
                        >
                          {numberFormat(item.commentscounts)}
                        </Styles.Text>
                      </Styles.ContentSummaryItem>
                    </Styles.ContentSummaryWrap>
                    <Styles.LinkButton onClick={() => onNavigate(item.id)}>
                      <Styles.Text
                        $styles={{
                          whiteSpace: "nowrap",
                          fontSize: "14px",
                          fontWeight: "600",
                          color: "#ffffff",
                          lineHeight: "16.8px",
                        }}
                      >
                        바로 가기
                      </Styles.Text>
                    </Styles.LinkButton>
                  </Styles.FlexRowLayout>
                </Styles.ContentItem>
              );
            })}

          {searchData?.items!?.length <= 0 && (
            <Styles.EmptyImage
              src={isLoading ? LOADING_SPINNER : EMPTY_IMAGE}
              alt="pickus"
              $styles={{
                width: isLoading ? "50px" : "auto",
                height: isLoading ? "50px" : "auto",
                objectFit: "cover",
              }}
            />
          )}
        </Styles.ContentWrap>
      </Styles.ContentSection>

      {searchData!?.items!?.length > 0 && (
        <Styles.InfinityScrollSection>
          {isLoading ? (
            <Styles.LoadingImage src={LOADING_SPINNER} alt="pickus" />
          ) : (
            <Styles.InfinityScrollButton onClick={() => onFetchMore()}>
              <Styles.Text
                $styles={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#82838E",
                  lineHeight: "16.8px",
                }}
              >
                더 보기
              </Styles.Text>

              <Styles.ArrowIcon src={ARROW_DOWN_GRAY} alt="pickus" />
            </Styles.InfinityScrollButton>
          )}
        </Styles.InfinityScrollSection>
      )}
    </Styles.Search>
  );
};

export default SearchPage;

interface ElementProps {
  $isBorderTopNone?: boolean;
  $isBorderBottomNone?: boolean;
  $active?: boolean;
}

const Styles = {
  Search: styled.div`
    font-family: "Pretendard";
    width: 100%;
    padding: 0px 0px 126px 0px;
  `,

  SearchBannerSection: styled.div`
    width: 100%;
    padding: 56px 0px 56px 0px;
    background: linear-gradient(91.48deg, #fbf9f6 -5.51%, #fbf4e8 117.06%);

    @media (max-width: ${"768px"}) {
      padding: 20px 0px 20px 20px;
    }
  `,

  SearchBanner: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
  `,

  FilterSection: styled.div<ElementProps>`
    width: 100%;
    border-top: 1px solid #e7ebf3;
    border-bottom: ${({ $isBorderBottomNone }) =>
      !$isBorderBottomNone && "1px solid #e7ebf3"};

    @media (max-width: ${"768px"}) {
      border: none;
    }
  `,

  FilterHeader: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
  `,

  FilterButton: styled.div`
    width: 184px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #e7ebf3;
    padding: 12px 12px 12px 0px;
  `,

  ArrowIcon: styled.img`
    cursor: pointer;
  `,

  SelectedFilter: styled.div``,

  FilterContentWrap: styled.div`
    width: 100%;
    border-top: 1px solid #e7ebf3;
    border-bottom: 1px solid #e7ebf3;
    background-color: #ffffff;

    @media (max-width: ${"768px"}) {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      z-index: 10;
      overflow: scroll;
    }
  `,

  FilterContent: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
  `,

  FilterItem: styled.div<ElementProps>`
    display: flex;
    border-left: 1px solid #e7ebf3;
    border-top: ${({ $isBorderTopNone }) =>
      !$isBorderTopNone && "1px solid #e7ebf3"};
    background: #f9f9f9;

    @media (max-width: ${"768px"}) {
      flex-direction: column;
      border: none;
      background: none;
    }
  `,

  FilterTitle: styled.div`
    width: 100%;
    display: flex;
    padding: 12px 12px 12px 16px;
    max-width: 182px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    color: #534d4d;

    @media (max-width: ${"768px"}) {
      color: #202123;
    }
  `,

  FilterValue: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px 12px 8px 16px;
    border-left: 1px solid #e7ebf3;
    border-right: 1px solid #e7ebf3;
    background-color: #ffffff;

    @media (max-width: ${"768px"}) {
      border: none;
      padding: 0px 12px 16px 16px;
      border-bottom: 1px solid #e7ebf3;
    }
  `,

  ChannelSearchInputWrapper: styled.div`
    width: 100%;
    max-width: 480px;
    display: flex;
    align-items: center;
    background-color: #eeeff1;
    border-radius: 100px;
    padding: 8px 16px 8px 16px;
  `,

  SearchIcon: styled.img`
    cursor: pointer;
  `,

  ChannelSearchInput: styled.input`
    width: 100%;
    border: none;
    font-size: 18px;
    font-weight: 500;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #9b9b9b;
    }
  `,

  SearchInputWrapper: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #eeeff1;
    border-radius: 100px;
    padding: 8px 16px 8px 16px;
  `,

  SearchInput: styled.input`
    width: 100%;
    border: none;
    font-size: 18px;
    font-weight: 500;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #9b9b9b;
    }
  `,

  SelectedFilterWrap: styled.div<ElementProps>`
    display: flex;
    border-left: 1px solid #e7ebf3;
    border-top: ${({ $isBorderTopNone }) =>
      !$isBorderTopNone && "1px solid #e7ebf3"};
    background: #ffffff;
  `,

  SelectedFilterTitle: styled.div`
    width: 100%;
    display: flex;
    padding: 12px 12px 12px 16px;
    max-width: 182px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    color: #534d4d;
  `,

  SelectedFilterValue: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px 12px 8px 16px;
    border-left: 1px solid #e7ebf3;
    border-right: 1px solid #e7ebf3;
    background-color: #ffffff;
  `,

  BorderArrowDownIcon: styled.img``,

  Chip: styled.button<ElementProps>`
    padding: 6px 12px 6px 12px;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid ${({ $active }) => ($active ? "#FF9839" : "#EAEEF5")};
    background-color: #ffffff;
    cursor: pointer;
  `,

  ContentSection: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 48px 0px 24px 0px;
    overflow: auto;

    @media (max-width: ${"768px"}) {
      margin: 0px 0px 24px 0px;
    }
  `,

  ContentHeader: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${"768px"}) {
      padding: 15px 16px 15px 16px;
    }
  `,

  ContentWrap: styled.div`
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: ${"768px"}) {
      width: 100%;
    }
  `,

  EmptyImage: styled.img<StyleProps>`
    width: 171px;
    height: 128px;
    margin: 120px auto;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  ContentItem: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 24px 16px 24px;
    border: 1px solid #e7ebf3;
    border-radius: 12px;

    @media (max-width: ${"768px"}) {
      padding: 16px 16px 16px 16px;
      flex-direction: column;
      border: none;
      border-bottom: 1px solid #e7ebf3;
      border-radius: 0;
    }
  `,

  ContentInfoWrap: styled.div`
    width: 100%;
    max-width: 379px;

    @media (max-width: ${"768px"}) {
      width: max-content;
    }
  `,

  ThumbnailImage: styled.img`
    width: 168px;
    height: 94px;
    border-radius: 5px;
    object-fit: cover;
    background-color: #dfdfdf;

    @media (max-width: ${"768px"}) {
      width: 88px;
      min-width: 88px;
      height: 54px;
    }
  `,

  CategoryBadgeWrap: styled.div`
    display: flex;
    align-items: center;
    margin: 12px 0px 0px 0px;
    gap: 8px;
    overflow: auto;
    cursor: grab;

    &::-webkit-scrollbar {
      display: none;
    }

    &:active {
      cursor: grabbing;
    }

    @media (max-width: ${"768px"}) {
      width: 100%;
    }
  `,

  CategoryBadge: styled.div`
    width: max-content;
    white-space: nowrap;
    padding: 3px 8px 3px 8px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.6px;
    color: #4b4c57;
    background: #eaeef5;
  `,

  ContentSummaryWrap: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    @media (max-width: ${"768px"}) {
      padding: 8px 0px 8px 0px;
      background-color: #fafbfc;
    }
  `,

  ContentSummaryItem: styled.div<StyleProps>`
    width: 100%;
    max-width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;

    @media (max-width: ${"768px"}) {
      border-right: ${({ $borderRightNone }) =>
        $borderRightNone ? "none" : "1px solid #e7ebf3"};
    }
  `,

  LinkButton: styled.div`
    text-align: center;
    padding: 12px 20px 12px 20px;
    border-radius: 8px;
    background: #ff9839;
    cursor: pointer;
  `,

  SortFilter: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  `,

  SortFilterList: styled.div`
    width: max-content;
    position: absolute;
    right: 0;
    top: 28px;
    padding: 16px 0px 16px 0px;
    border: 1px solid #d2d2d2;
    border-radius: 8px;
    background-color: #ffffff;
  `,

  SortFilterItem: styled.div<ElementProps>`
    font-size: 15px;
    font-weight: 500;
    padding: 12px 24px 12px 24px;
    color: ${({ $active }) => ($active ? "#FF9839" : "#28282B")};
    cursor: pointer;

    &:hover {
      background: #f3f5f8;
    }
  `,

  InfinityScrollSection: styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: center;
  `,

  InfinityScrollButton: styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 12px 16px 12px 20px;
    border-radius: 8px;
    background: #eaeef5;
    cursor: pointer;
  `,

  LoadingImage: styled.img`
    width: 24px;
    height: 24px;
  `,

  Text: styled.div<StyleProps>`
    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  PcVisible: styled.div<StyleProps>`
    display: block;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      display: none;
    }
  `,

  MobileVisible: styled.div`
    display: none;

    @media (max-width: ${"768px"}) {
      display: block;
    }
  `,

  FlexRowLayout: styled.div<StyleProps>`
    display: flex;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  FlexColumnLayout: styled.div<StyleProps>`
    display: flex;
    flex-direction: column;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,
};
