import { Chart } from "react-google-charts";
import styled from "styled-components";
import { useState } from "react";
import CHECK_IMAGE from "../../../assets/images/check_image.png";
import ARROW_DOWN_BUTTON from "../../../assets/images/arrow_down_button.svg";

const LineChart = () => {
  const [showViews, setShowViews] = useState(true);
  const [showComments, setShowComments] = useState(true);
  const [showLikes, setShowLikes] = useState(true);

  const [tabIndex, setTabIndex] = useState(0);

  const data = [
    ["Year", "조회수", "댓글", "좋아요"],
    ["2006", 1000, 4000, 300],
    ["2007", 1170, 4600, 780],
    ["2008", 6600, 1120, 4900],
    ["2009", 1030, 5400, 3020],
    ["2010", 2030, 9400, 1020],
    ["2011", 3030, 2400, 2020],
    ["2012", 4030, 5400, 7020],
    ["2013", 5030, 7400, 6020],
    ["2014", 5030, 7400, 6020],
    ["2015", 6030, 2400, 5020],
    ["2016", 3030, 7400, 8020],
    ["2017", 2030, 5400, 4000],
    ["2018", 6030, 4400, 4500],
    ["2019", 4030, 9400, 4000],
    ["2020", 8030, 8400, 4500],
    ["2021", 1030, 2400, 5000],
    ["2022", 4030, 4400, 6000],
    ["2023", 1030, 4400, 4000],
  ];

  const options = {
    curveType: "function",
    legend: "none",
    series: {
      0: {
        visibleInLegend: showViews,
        lineWidth: showViews ? 2 : 0,
        color: "#F68445",
      }, // 조회수
      1: {
        visibleInLegend: showComments,
        lineWidth: showComments ? 2 : 0,
        color: "#31EB87",
      }, // 댓글
      2: {
        visibleInLegend: showLikes,
        lineWidth: showLikes ? 2 : 0,
        color: "#458BF6",
      }, // 좋아요
    },
    hAxis: {
      ticks: [2006, 2018, 2020, 2024],
    },

    chartArea: { width: "90%", height: "90%" },
  };

  return (
    <Styles.GraphContainer>
      <Styles.ChartWrap>
        <Styles.Text
          $styles={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#202123",
            lineHeight: "24px",
          }}
        >
          키워드 그래프
        </Styles.Text>

        <Styles.FlexRowLayout
          $styles={{
            alignItems: "center",
            gap: "6px",
            margin: "8px 0px 0px 0px",
          }}
        >
          <Styles.Text
            $styles={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#82838e",
              lineHeight: "19.2px",
            }}
          >
            날짜 범위
          </Styles.Text>

          <Styles.FlexRowLayout $styles={{ alignItems: "center", gap: "4px" }}>
            <Styles.Text
              $styles={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#202123",
                lineHeight: "19.2px",
              }}
            >
              2024.07.24
            </Styles.Text>
            <Styles.Text
              $styles={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#202123",
                lineHeight: "19.2px",
              }}
            >
              -
            </Styles.Text>
            <Styles.Text
              $styles={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#202123",
                lineHeight: "19.2px",
              }}
            >
              2024.10.24
            </Styles.Text>
          </Styles.FlexRowLayout>

          <Styles.ArrowDownButton src={ARROW_DOWN_BUTTON} alt="pickus" />
        </Styles.FlexRowLayout>

        <Styles.RankingTab>
          <Styles.Tab $active={tabIndex === 0} onClick={() => setTabIndex(0)}>
            일
          </Styles.Tab>
          <Styles.Tab $active={tabIndex === 1} onClick={() => setTabIndex(1)}>
            주
          </Styles.Tab>
          <Styles.Tab $active={tabIndex === 2} onClick={() => setTabIndex(2)}>
            월
          </Styles.Tab>
        </Styles.RankingTab>

        <Styles.ChartBox>
          <Chart
            chartType="LineChart"
            data={data}
            options={options}
            legendToggle
            style={{
              width: "fit-content",
              height: "fit-content",
              minWidth: "896px",
              minHeight: "428px",
            }}
          />
        </Styles.ChartBox>
      </Styles.ChartWrap>

      <Styles.SettingWrap>
        <Styles.SettingHeader>
          <Styles.Text
            $styles={{
              fontSize: "20px",
              fontWeight: "700",
              color: "#202123",
              lineHeight: "24px",
            }}
          >
            키워드 리스트
          </Styles.Text>
          <Styles.Text
            $styles={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#82838E",
              lineHeight: "19.2px",
            }}
          >
            원하는 키워드를 선택해주세요.
          </Styles.Text>
        </Styles.SettingHeader>

        <Styles.ToggleWrap>
          <Styles.ToggleLabel onClick={() => setShowViews((prev) => !prev)}>
            <Styles.Toggle $active={showViews}>
              <Styles.CheckImage src={CHECK_IMAGE} alt="pickus" />
            </Styles.Toggle>
            <Styles.FlexRowLayout
              $styles={{ alignItems: "center", gap: "7px" }}
            >
              <Styles.Dot
                $styles={{
                  width: "12px",
                  height: "12px",
                  background: "#F68445",
                  borderRadius: "100px",
                }}
              ></Styles.Dot>
              <Styles.Text
                $styles={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#202123",
                  lineHeight: "19.2px",
                }}
              >
                뉴진스
              </Styles.Text>
            </Styles.FlexRowLayout>
          </Styles.ToggleLabel>

          <Styles.ToggleLabel onClick={() => setShowComments((prev) => !prev)}>
            <Styles.Toggle $active={showComments}>
              <Styles.CheckImage src={CHECK_IMAGE} alt="pickus" />
            </Styles.Toggle>
            <Styles.FlexRowLayout
              $styles={{ alignItems: "center", gap: "7px" }}
            >
              <Styles.Dot
                $styles={{
                  width: "12px",
                  height: "12px",
                  background: "#31EB87",
                  borderRadius: "100px",
                }}
              ></Styles.Dot>
              <Styles.Text
                $styles={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#202123",
                  lineHeight: "19.2px",
                }}
              >
                유진스
              </Styles.Text>
            </Styles.FlexRowLayout>
          </Styles.ToggleLabel>

          <Styles.ToggleLabel onClick={() => setShowLikes((prev) => !prev)}>
            <Styles.Toggle $active={showLikes}>
              <Styles.CheckImage src={CHECK_IMAGE} alt="pickus" />
            </Styles.Toggle>
            <Styles.FlexRowLayout
              $styles={{ alignItems: "center", gap: "7px" }}
            >
              <Styles.Dot
                $styles={{
                  width: "12px",
                  height: "12px",
                  background: "#458BF6",
                  borderRadius: "100px",
                }}
              ></Styles.Dot>
              <Styles.Text
                $styles={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#202123",
                  lineHeight: "19.2px",
                }}
              >
                뉴진스님
              </Styles.Text>
            </Styles.FlexRowLayout>
          </Styles.ToggleLabel>
        </Styles.ToggleWrap>
      </Styles.SettingWrap>
    </Styles.GraphContainer>
  );
};

export default LineChart;

interface StyleProps {
  $styles?: React.CSSProperties | null;
  $mobileStyles?: React.CSSProperties | null;
  $borderRightNone?: boolean | null;
  $active?: boolean | null;
}

interface ToggleProps {
  $active?: boolean | null;
}

const Styles = {
  GraphContainer: styled.div`
    width: 100%;
    display: flex;
  `,

  ChartWrap: styled.div`
    position: relative;
    min-width: 924px;
    height: 607px;
    border-right: 1px solid #e7ebf3;
    padding: 28px;

    &rect {
      width: 100% !important;
    }

    @media (max-width: 768px) {
      min-width: 100%;
      border: none;
    }
  `,

  ChartBox: styled.div`
    overflow-x: auto;
    overflow-y: hidden;
  `,

  RankingTab: styled.div`
    width: max-content;
    margin-left: auto;
    display: flex;
    gap: 4px;
    background-color: #f3f5f8;
    border-radius: 6px;
  `,

  Tab: styled.div<StyleProps>`
    width: 32px;
    height: 32px;
    flex: 1;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 0px 7px 0px;
    text-align: center;
    background-color: ${({ $active }) => ($active ? "#ffffff" : "none")};
    color: ${({ $active }) => ($active ? "#202123" : "#B2B3BC")};
    border-radius: ${({ $active }) => ($active ? "5px" : "none")};
    border: ${({ $active }) => ($active ? "1px solid #E7EBF3" : "none")};
    box-shadow: ${({ $active }) =>
      $active ? "0px 4px 15px 0px #00000012" : "none"};

    cursor: pointer;
  `,

  ArrowDownButton: styled.img`
    cursor: pointer;
  `,

  SettingWrap: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 28px;

    @media (max-width: 768px) {
      display: none;
    }
  `,

  SettingHeader: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,

  ToggleWrap: styled.div`
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 24px;
  `,

  CheckImage: styled.img`
    width: 14px;
    height: 11px;
  `,

  Toggle: styled.button<ToggleProps>`
    min-width: 24px;
    height: 24px;
    border-radius: 5px;
    background-color: ${({ $active }) => ($active ? "#2a2b30" : "white")};
    border: ${({ $active }) => ($active ? "none" : "1px solid #2a2b30")};
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,

  ToggleLabel: styled.div`
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
  `,

  Dot: styled.div<StyleProps>`
    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  Text: styled.div<StyleProps>`
    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  PcVisible: styled.div<StyleProps>`
    display: block;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      display: none;
    }
  `,

  MobileVisible: styled.div`
    display: none;

    @media (max-width: ${"768px"}) {
      display: block;
    }
  `,

  FlexRowLayout: styled.div<StyleProps>`
    display: flex;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,

  FlexColumnLayout: styled.div<StyleProps>`
    display: flex;
    flex-direction: column;

    ${({ $styles }) => $styles && { ...$styles }};

    @media (max-width: ${"768px"}) {
      ${({ $mobileStyles }) => $mobileStyles && { ...$mobileStyles }};
    }
  `,
};
