import { ListItem } from "models/commonModels/ListModels";
import { FilterItem } from "models/filterModels/FilterModels";
import { FilterValues } from "models/searchModels/SearchModels";
import { RegionData } from "models/commonModels/ListModels";

const viewFilterList: FilterItem[] = [
  { id: 1, label: "1만 +", value: "10000" },
  { id: 2, label: "5만 +", value: "50000" },
  { id: 3, label: "10만 +", value: "100000" },
  { id: 4, label: "20만 +", value: "200000" },
  { id: 5, label: "50만 +", value: "500000" },
  { id: 6, label: "1백만 +", value: "1000000" },
  { id: 7, label: "2백만 +", value: "2000000" },
];

const videoTypeFilterList: FilterItem[] = [
  { id: 1, label: "동영상", value: "video" },
  { id: 2, label: "Shorts", value: "shorts" },
  { id: 3, label: "라이브 영상", value: "live" },
];

const durationFilterList: FilterItem[] = [
  { id: 1, label: "15초", value: "15s" },
  { id: 2, label: "30초", value: "30s" },
  { id: 3, label: "1분", value: "60s" },
  { id: 4, label: "3분 이상", value: "180s" },
];

const uploadTimeFilterList: FilterItem[] = [
  { id: 1, label: "최근 24시간", value: "24h" },
  { id: 2, label: "최근 7일", value: "7d" },
  { id: 3, label: "최근 15일", value: "15d" },
  { id: 4, label: "최근 30일", value: "30d" },
];

const categoryFilterList: FilterItem[] = [
  { id: 1, label: "애완동물/동물", value: "music" },
  { id: 2, label: "음악", value: "music" },
  { id: 3, label: "게임", value: "game" },
  { id: 4, label: "뉴스/정치", value: "news" },
  { id: 5, label: "인물/블로그", value: "blog" },
  { id: 6, label: "여행/이벤트", value: "travel" },
  { id: 7, label: "스포츠", value: "sports" },
  { id: 8, label: "자동차/교통", value: "car" },
  { id: 9, label: "코미디", value: "comedy" },
  { id: 10, label: "코미디", value: "comedy" },
  { id: 11, label: "코미디", value: "comedy" },
  { id: 12, label: "코미디", value: "comedy" },
  { id: 13, label: "코미디", value: "comedy" },
  { id: 14, label: "코미디", value: "comedy" },
  { id: 15, label: "코미디", value: "comedy" },
  { id: 16, label: "코미디", value: "comedy" },
  { id: 17, label: "코미디", value: "comedy" },
  { id: 18, label: "코미디", value: "comedy" },
];

const genreFilterList: FilterItem[] = [
  { id: 1, label: "코미디", value: "comedy" },
  { id: 2, label: "드라마", value: "drama" },
  { id: 3, label: "스릴러", value: "thriller" },
  { id: 4, label: "다큐멘터리", value: "documentary" },
];

const countryFilterList: FilterItem[] = [
  { id: 1, label: "한국", value: "KR" },
  { id: 2, label: "미국", value: "US" },
  { id: 3, label: "타이완", value: "TW" },
  { id: 4, label: "인도네시아", value: "ID" },
  { id: 5, label: "베트남", value: "VN" },
  { id: 6, label: "인도", value: "IN" },
];

const sortFilterList: FilterItem[] = [
  {
    id: 1,
    label: "관련도순",
    value: "relevance",
  },
  {
    id: 2,
    label: "최근 등록 순",
    value: "latest",
  },
  {
    id: 3,
    label: "오래된 순",
    value: "oldest",
  },
  {
    id: 4,
    label: "조회수가 많은 순",
    value: "most_viewed",
  },
  {
    id: 5,
    label: "좋아요가 많은 순",
    value: "most_liked",
  },
  {
    id: 6,
    label: "댓글이 많은 순",
    value: "most_commented",
  },
  {
    id: 7,
    label: "라이브 시청자가 많은 순",
    value: "most_live_viewers",
  },
];

const filterValues: FilterValues = {
  q: "청바지",
  regionCode: "KR",
  nextPageToken: "CB4QAA",
  resultnum: 30,
  items: [
    {
      id: "xAR9MnxqGwo",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/xAR9MnxqGwo/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/xAR9MnxqGwo/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/xAR9MnxqGwo/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/xAR9MnxqGwo/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/xAR9MnxqGwo/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "·˚ ༘ ♡ ˚꒰ 🐾〻♡ 171cm 청바지 룩북 ·˚ ༘ ♡ ˚꒰ 🐾〻♡",
      viewcounts: "35751",
      likecounts: "0",
      commentscounts: "0",
      tags: "None",
      pdate: "2024-08-24T04:27:19Z",
    },
    {
      id: "9258RH0_atY",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/9258RH0_atY/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/9258RH0_atY/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/9258RH0_atY/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/9258RH0_atY/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/9258RH0_atY/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "흰티에 청바지 코디 추천 #shorts #20대코디",
      viewcounts: "682643",
      likecounts: "10783",
      commentscounts: "143",
      tags: "None",
      pdate: "2023-04-29T08:30:02Z",
    },
    {
      id: "TtlPkNyEO00",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/TtlPkNyEO00/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/TtlPkNyEO00/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/TtlPkNyEO00/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/TtlPkNyEO00/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/TtlPkNyEO00/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "[ 165cm, 65kgㅣ하체통통이 ] 바지핏의 중요성 (빅사이즈 데님, 빅사이즈 와이드팬츠)",
      viewcounts: "3783191",
      likecounts: "25444",
      commentscounts: "843",
      tags: [
        "통통녀",
        "77사이즈룩북",
        "88사이즈룩북",
        "99사이즈룩북",
        "100사이즈룩북",
        "빅사이즈쇼핑몰",
        "빅사이즈하울",
        "플러스사이즈",
        "빅사이즈",
        "모델",
        "옷",
        "패션",
        "데일리룩",
        "통통녀코디",
        "뚱뚱녀코디",
        "체형커버룩",
        "간절기룩",
        "미리가을",
        "가을룩북",
        "빅사이즈 코디",
        "플러스사이즈 모델",
        "빅사이즈 코디 추천",
        "체형커버",
        "통통",
        "30대 하객룩",
        "40대 하객룩",
        "통통 하객룩",
        "통통패션",
        "통통 코디",
        "통통 가을 룩북",
        "가을 패션",
        "여자 가을 패션",
        "가을 스타일링",
        "여자 가을 코디",
        "여자 가을 스타일링",
        "30대 가을",
        "40대 가을",
        "20대 빅사이즈",
        "30대 빅사이즈",
        "40대 빅사이즈",
        "빅사이즈 오피스룩",
        "77사이즈 오피스룩",
        "88사이즈 오피스룩",
        "빅사이즈 출근룩",
        "하체비만",
        "하비커버",
        "하비커버 코디",
        "하체비만 커버룩",
        "하체통통 커버",
        "하체통통녀",
        "하비체형커버",
        "빅사이즈 청바지",
        "빅사이즈 데님",
        "빅사이즈 팬츠",
        "빅사이즈 팬츠 코디",
        "빅사이즈 바지 쇼핑몰",
        "빅사이즈 와이드 팬츠",
        "빅사이즈 바지 코디",
      ],
      pdate: "2021-10-07T11:54:31Z",
    },
    {
      id: "X5wy4FNGLnQ",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/X5wy4FNGLnQ/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/X5wy4FNGLnQ/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/X5wy4FNGLnQ/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/X5wy4FNGLnQ/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/X5wy4FNGLnQ/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "남자 청바지, 딱 필요한 것만 골라드림 (+ 리바이스 제발 사지 마세요)",
      viewcounts: "135779",
      likecounts: "1615",
      commentscounts: "139",
      tags: "None",
      pdate: "2024-01-27T04:00:19Z",
    },
    {
      id: "vWCmaX3d_sk",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/vWCmaX3d_sk/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/vWCmaX3d_sk/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/vWCmaX3d_sk/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/vWCmaX3d_sk/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/vWCmaX3d_sk/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "인생핏 보장!! 내돈내산 청바지 맛집 추천 BEST 7",
      viewcounts: "186740",
      likecounts: "7234",
      commentscounts: "19",
      tags: "None",
      pdate: "2024-09-08T11:30:28Z",
    },
    {
      id: "TCBuiMdxNgs",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/TCBuiMdxNgs/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/TCBuiMdxNgs/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/TCBuiMdxNgs/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/TCBuiMdxNgs/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/TCBuiMdxNgs/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "지그재그 랭킹 1위 청바지👖✨ 살까 말까 고민 중이라면! 특징 3가지🩵",
      viewcounts: "61791",
      likecounts: "1656",
      commentscounts: "19",
      tags: "None",
      pdate: "2024-09-16T12:00:36Z",
    },
    {
      id: "AZ8xQ8LcsJs",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/AZ8xQ8LcsJs/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/AZ8xQ8LcsJs/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/AZ8xQ8LcsJs/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/AZ8xQ8LcsJs/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/AZ8xQ8LcsJs/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "지금 따라입기 좋은 중년여성 청바지 패션👖",
      viewcounts: "108482",
      likecounts: "1048",
      commentscounts: "5",
      tags: "None",
      pdate: "2024-09-19T04:45:00Z",
    },
    {
      id: "BgTdNOj8CbI",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/BgTdNOj8CbI/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/BgTdNOj8CbI/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/BgTdNOj8CbI/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/BgTdNOj8CbI/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/BgTdNOj8CbI/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "99%는 모르는 청바지핏 살리는 법 5가지",
      viewcounts: "1286793",
      likecounts: "25562",
      commentscounts: "264",
      tags: [
        "청바지",
        "청바지추천",
        "청바지핏",
        "패션꿀팁",
        "남자청바지",
        "바지",
        "바지추천",
        "청바지구매",
        "와이드핏",
        "남자바지",
      ],
      pdate: "2023-02-13T02:09:27Z",
    },
    {
      id: "n8piqvP0-dQ",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/n8piqvP0-dQ/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/n8piqvP0-dQ/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/n8piqvP0-dQ/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/n8piqvP0-dQ/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/n8piqvP0-dQ/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "요즘 가장 핫한 청바지 BEST 5",
      viewcounts: "966469",
      likecounts: "19409",
      commentscounts: "86",
      tags: "None",
      pdate: "2023-05-19T11:30:12Z",
    },
    {
      id: "sB3JLHF7wAU",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/sB3JLHF7wAU/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/sB3JLHF7wAU/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/sB3JLHF7wAU/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/sB3JLHF7wAU/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/sB3JLHF7wAU/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "오늘부터 청바지는 이렇게 입으시는 겁니다 #shorts",
      viewcounts: "595087",
      likecounts: "10796",
      commentscounts: "649",
      tags: ["#패션약국", "#너도입어보세요", "#댓츠기릿"],
      pdate: "2023-03-05T10:02:00Z",
    },
    {
      id: "FQXfLOx_Gh0",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/FQXfLOx_Gh0/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/FQXfLOx_Gh0/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/FQXfLOx_Gh0/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/FQXfLOx_Gh0/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/FQXfLOx_Gh0/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "올시즌 유행하는 청바지 스타일은? #중년패션코디",
      viewcounts: "236357",
      likecounts: "1772",
      commentscounts: "9",
      tags: "None",
      pdate: "2024-03-18T02:55:53Z",
    },
    {
      id: "euFfK62TMXM",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/euFfK62TMXM/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/euFfK62TMXM/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/euFfK62TMXM/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/euFfK62TMXM/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/euFfK62TMXM/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "봄여름 일주일출근룩🐳 #shorts #출근룩 #셔츠추천 #청바지추천 #갓성비 #가방추천 #보부상가방",
      viewcounts: "232727",
      likecounts: "3436",
      commentscounts: "45",
      tags: "None",
      pdate: "2024-04-17T09:59:29Z",
    },
    {
      id: "qHzxivvEtiM",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/qHzxivvEtiM/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/qHzxivvEtiM/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/qHzxivvEtiM/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/qHzxivvEtiM/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/qHzxivvEtiM/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "👖주우재가 알려주는 가격대별 데님 추천 | 리바이스, 유니클로, 풀카운트, 웨어하우스, 슈가케인, 브론슨, 논스탁, 페로우즈, 제라도",
      viewcounts: "265919",
      likecounts: "3706",
      commentscounts: "323",
      tags: [
        "주우재",
        "브이로그",
        "주우재패션",
        "남자코디",
        "vlog",
        "fashion",
        "주우재 인스타",
        "주우재 옷 정보",
        "주우재 신발",
        "주우재 옷",
        "주우재 패션",
        "주우재 코디",
        "남친룩 코디",
        "주우재데님",
        "주우재바지",
        "주우재팬츠",
        "데님",
        "팬츠",
        "바지",
        "셀비지데님",
        "리바이스",
        "유니클로",
        "풀카운트",
        "웨어하우스",
        "페로우즈",
        "슈가케인",
        "아웃스탠딩",
        "브론슨",
        "논스탁",
        "더블알엘",
        "tcb진스",
        "LVC",
        "블랙사인",
        "제라도",
        "나이젤카본",
        "캐피탈",
        "트로피클로딩",
        "복각",
        "복각데님",
        "1947",
        "1944",
        "1955",
        "501",
        "리지드",
        "원워시",
      ],
      pdate: "2024-09-20T10:00:53Z",
    },
    {
      id: "ccnxbWdYqY4",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/ccnxbWdYqY4/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/ccnxbWdYqY4/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/ccnxbWdYqY4/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/ccnxbWdYqY4/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/ccnxbWdYqY4/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "퀄리티, 가성비 둘 다 챙긴 청바지 브랜드 추천 👖",
      viewcounts: "150122",
      likecounts: "6820",
      commentscounts: "41",
      tags: "None",
      pdate: "2024-02-04T03:30:14Z",
    },
    {
      id: "tBS9Wl_5Kc4",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/tBS9Wl_5Kc4/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/tBS9Wl_5Kc4/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/tBS9Wl_5Kc4/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/tBS9Wl_5Kc4/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/tBS9Wl_5Kc4/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "지금 뜨고 있는 데님 비밀공식🤫 요즘 매일 입는 인생청바지👖",
      viewcounts: "480134",
      likecounts: "5909",
      commentscounts: "238",
      tags: [
        "청바지",
        "청바지 추천",
        "인생청바지",
        "뜨는패션",
        "지는패션",
        "2024",
      ],
      pdate: "2024-04-03T11:00:57Z",
    },
    {
      id: "SUPFc4k-hcQ",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/SUPFc4k-hcQ/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/SUPFc4k-hcQ/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/SUPFc4k-hcQ/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/SUPFc4k-hcQ/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/SUPFc4k-hcQ/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "여러분의 보물 1호가 될 수도 있습니다. 입을수록 맘에드는 청바지 7가지 추천",
      viewcounts: "1024633",
      likecounts: "14364",
      commentscounts: "460",
      tags: "None",
      pdate: "2022-05-14T03:00:08Z",
    },
    {
      id: "KITF8MyldIM",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/KITF8MyldIM/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/KITF8MyldIM/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/KITF8MyldIM/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/KITF8MyldIM/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/KITF8MyldIM/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "'LVC' 남자라면 죽기전에 꼭 입어야 할 청바지 #리바이스 #데님팬츠 #청바지 #LVC #47501 #54501 #55501 #핏비교",
      viewcounts: "5451",
      likecounts: "55",
      commentscounts: "17",
      tags: "None",
      pdate: "2024-09-12T07:56:22Z",
    },
    {
      id: "FuEbM8Q8wuc",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/FuEbM8Q8wuc/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/FuEbM8Q8wuc/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/FuEbM8Q8wuc/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/FuEbM8Q8wuc/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/FuEbM8Q8wuc/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "청바지 100%로 살리는 방법! 청바지 여쁘게 관리하는 법까지 알려드릴게요~!! #청바지코디 #청바지 #패션꿀팁",
      viewcounts: "104240",
      likecounts: "1163",
      commentscounts: "0",
      tags: "None",
      pdate: "2024-08-14T09:28:39Z",
    },
    {
      id: "71-gqtq4HtA",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/71-gqtq4HtA/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/71-gqtq4HtA/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/71-gqtq4HtA/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/71-gqtq4HtA/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/71-gqtq4HtA/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "99%가 모르는 청바지와 신발 조합",
      viewcounts: "980896",
      likecounts: "13565",
      commentscounts: "75",
      tags: [
        "깡스타일리스트",
        "날씨",
        "가성비",
        "스트릿패션",
        "남자패션",
        "미니멀패션",
        "남친룩",
        "캐주얼패션",
        "남자캐주얼",
        "남자미니멀룩",
        "남자기본템추천",
        "남자데이트룩",
        "남자바지",
        "남자겨울옷",
        "겨울",
        "바지추천",
        "바지맛집",
        "청바지",
        "슬랙스",
        "와이드팬츠",
        "슬랙스추천",
        "청바지추천",
        "무신사스탠다드",
        "무탠다드",
        "모드나인",
        "슬림",
        "테이퍼드",
        "스트릿",
        "신발",
        "스니커즈",
        "운동화",
        "나이키",
        "반스",
        "미하라야스히로",
        "부츠",
        "구두",
        "로퍼",
      ],
      pdate: "2023-01-02T12:00:04Z",
    },
    {
      id: "mfcenm1DNIE",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/mfcenm1DNIE/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/mfcenm1DNIE/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/mfcenm1DNIE/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/mfcenm1DNIE/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/mfcenm1DNIE/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "알아두면 인생이 바뀌는 체형별 추천 청바지핏🚩",
      viewcounts: "34912",
      likecounts: "374",
      commentscounts: "4",
      tags: "None",
      pdate: "2024-09-11T04:45:04Z",
    },
    {
      id: "_hhCT5P9QOc",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/_hhCT5P9QOc/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/_hhCT5P9QOc/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/_hhCT5P9QOc/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/_hhCT5P9QOc/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/_hhCT5P9QOc/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "유니클로 청바지 추천 3가지! #바지추천",
      viewcounts: "42294",
      likecounts: "393",
      commentscounts: "13",
      tags: [
        "uniqlo",
        "유니클로 바지",
        "유니클로 청바지",
        "청바지 추천",
        "shorts",
        "남자 패션 유튜버",
      ],
      pdate: "2024-01-26T12:00:35Z",
    },
    {
      id: "knfi3Z2XY0A",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/knfi3Z2XY0A/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/knfi3Z2XY0A/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/knfi3Z2XY0A/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/knfi3Z2XY0A/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/knfi3Z2XY0A/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "✔️살짝 로우통바지 제대로 된거로 후다닥코디 짜봤어요 #청바지#와이드팬츠#가을코디#숏츠#데일리룩#ootd",
      viewcounts: "9591",
      likecounts: "93",
      commentscounts: "33",
      tags: "None",
      pdate: "2024-10-11T02:11:07Z",
    },
    {
      id: "ooTHAVp2VxQ",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/ooTHAVp2VxQ/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/ooTHAVp2VxQ/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/ooTHAVp2VxQ/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/ooTHAVp2VxQ/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/ooTHAVp2VxQ/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "남성 청바지 추천 이걸 놓치신다고요? 2023년 역대급 인기 가성비 최신순위 업데이트! 품절임박",
      viewcounts: "22137",
      likecounts: "91",
      commentscounts: "1",
      tags: [
        "여성 반바지",
        "여름청바지",
        "남자 바지",
        "게스청바지",
        "남자 청바지",
        "와이드 청바지",
        "남성 바지",
      ],
      pdate: "2023-06-22T08:22:14Z",
    },
    {
      id: "fEFpxc086QU",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/fEFpxc086QU/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/fEFpxc086QU/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/fEFpxc086QU/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/fEFpxc086QU/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/fEFpxc086QU/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "요즘 솔직히 이렇게 입으면 진짜 촌스러워요… 청바지 잘 고르는 공식 총정리✔️",
      viewcounts: "1131662",
      likecounts: "23038",
      commentscounts: "256",
      tags: [
        "청바지",
        "청바지꿀팁",
        "청바지추천",
        "청바지잘입는법",
        "데님팬츠",
        "여자코디",
        "기본템",
        "패션",
        "패션스타일리스트",
        "패션디자이너",
        "패션유튜버",
      ],
      pdate: "2023-10-16T12:00:28Z",
    },
    {
      id: "HoGpEJhFeso",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/HoGpEJhFeso/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/HoGpEJhFeso/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/HoGpEJhFeso/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/HoGpEJhFeso/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/HoGpEJhFeso/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "가성비 와이드 청바지 추천! 스파오 와이드 데님!! #shorts",
      viewcounts: "9341",
      likecounts: "88",
      commentscounts: "2",
      tags: [
        "스파오",
        "spao",
        "와이드 청바지",
        "와이드 데님",
        "청바지 추천",
        "데님팬츠 추천",
      ],
      pdate: "2024-04-04T14:00:15Z",
    },
    {
      id: "SV3n2UU7-JM",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/SV3n2UU7-JM/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/SV3n2UU7-JM/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/SV3n2UU7-JM/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/SV3n2UU7-JM/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/SV3n2UU7-JM/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "여러분의 인생 청바지가 될 수 있습니다. 가성비 말고 근본 데님 5가지 추천",
      viewcounts: "190481",
      likecounts: "2626",
      commentscounts: "237",
      tags: [
        "풀카운트",
        "풀카운트1101",
        "풀카운트0105",
        "풀카운트1108",
        "풀카운트0107",
        "웨어하우스",
        "웨어하우스데님",
        "웨어하우스1101",
        "웨어하우스1105",
        "풀카운트데님",
        "프리휠러스",
        "프리휠러스1947",
        "프리휠러스데님",
        "프리휠러스601xx1947",
        "곤조티비",
        "안태옥",
        "fols",
        "폴스데님",
        "올리브드랩서비스",
        "더블알엘",
        "rrl",
        "doublerl",
        "더블알엘데님",
        "워크웨어",
        "아메카지",
        "근본데님",
        "다나옷",
        "다나옷청바지",
        "핏더사이즈",
        "깡스타일리스트",
        "탈패션",
      ],
      pdate: "2023-02-08T09:46:00Z",
    },
    {
      id: "v5WqBrKJ8HQ",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/v5WqBrKJ8HQ/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/v5WqBrKJ8HQ/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/v5WqBrKJ8HQ/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/v5WqBrKJ8HQ/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/v5WqBrKJ8HQ/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title:
        "올가을 뜨는 청바지핏 이겁니다🔥가을코디 끝장낼 인생데님 BEST 6👖미친핏 직장인 데일리출근룩패션 개강룩 가을바지룩북 W컨셉 24FW falloutfit lookbook",
      viewcounts: "45045",
      likecounts: "817",
      commentscounts: "104",
      tags: [
        "데님",
        "청바지",
        "코디",
        "패션",
        "가을옷",
        "가을패션",
        "가을데님",
        "가을청바지",
        "가을코디",
        "소신사장",
        "어센티",
        "W컨셉",
        "29cm",
        "데님유행",
        "부츠컷",
        "와이드",
        "가을바지",
        "직장인",
        "브랜드추천",
        "20대패션",
        "30대패션",
        "40대패션",
        "룩북",
        "가을룩북",
        "출근룩",
        "outfits",
        "秋服",
        "출근",
        "가을옷추천",
        "재킷",
        "카디건",
        "복장",
        "다이어트",
        "패션코디",
        "20대코디",
        "직장인옷",
        "가을출근룩",
        "가을오피스룩",
        "하객룩",
        "가을데이트룩",
        "여친룩",
        "코디꿀팁",
        "데일리백",
        "하울",
        "lookbook",
        "면접룩",
        "신입사원룩",
        "이력서",
        "23FW",
        "가을트렌드",
        "가을운동화",
        "가을가방",
        "가을구두",
        "부츠코디",
        "가을",
        "가을패션여자",
        "개강",
        "falloutfit",
        "대학생",
        "캐주얼",
        "코디추천",
        "자라",
        "오피스룩",
        "가을옷코디",
        "가을기본템",
        "가을필수템",
        "제니",
        "개강룩",
        "한소희",
        "보라끌레르",
        "앨리스펑크",
        "최실장",
      ],
      pdate: "2024-08-30T11:15:07Z",
    },
    {
      id: "rYjzj73L4aM",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/rYjzj73L4aM/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/rYjzj73L4aM/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/rYjzj73L4aM/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/rYjzj73L4aM/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/rYjzj73L4aM/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "(광고X) 기본 데님 정비 합시다!👖가성비 청바지 추천",
      viewcounts: "118099",
      likecounts: "4416",
      commentscounts: "115",
      tags: "None",
      pdate: "2024-08-19T16:09:39Z",
    },
    {
      id: "2bylu3zStIs",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/2bylu3zStIs/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/2bylu3zStIs/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/2bylu3zStIs/hqdefault.jpg",
          width: 480,
          height: 360,
        },
        standard: {
          url: "https://i.ytimg.com/vi/2bylu3zStIs/sddefault.jpg",
          width: 640,
          height: 480,
        },
        maxres: {
          url: "https://i.ytimg.com/vi/2bylu3zStIs/maxresdefault.jpg",
          width: 1280,
          height: 720,
        },
      },
      title: "청바지는 그냥 이 중에서 고르면 됩니다.",
      viewcounts: "32728",
      likecounts: "667",
      commentscounts: "85",
      tags: "None",
      pdate: "2024-09-26T12:57:25Z",
    },
  ],
};

const recommendKeywords: ListItem[] = [
  { id: 0, value: "웰빙" },
  { id: 1, value: "워라밸" },
  { id: 2, value: "재택근무" },
  { id: 3, value: "MZ세대" },
  { id: 4, value: "플렉스" },
  { id: 5, value: "소확행" },
  { id: 6, value: "가성비" },
  { id: 7, value: "힐링" },
  { id: 8, value: "취미생활" },
  { id: 9, value: "액티비티" },
  { id: 10, value: "SNS" },
  { id: 11, value: "인플루언서" },
  { id: 12, value: "스타트업" },
  { id: 13, value: "부캐" },
  { id: 14, value: "밀키트" },
  { id: 15, value: "구독경제" },
  { id: 16, value: "친환경" },
  { id: 17, value: "건강관리" },
  { id: 18, value: "온라인 쇼핑" },
  { id: 19, value: "셀프케어" },
];

const relatedKeywords: ListItem[] = [
  { id: 0, value: "OTT 서비스" },
  { id: 1, value: "자율 주행" },
  { id: 2, value: "AI" },
  { id: 3, value: "메타버스" },
  { id: 4, value: "NFT" },
  { id: 5, value: "블록체인" },
  { id: 6, value: "빅데이터" },
  { id: 7, value: "클라우드" },
  { id: 8, value: "IoT" },
  { id: 9, value: "5G" },
  { id: 10, value: "로봇" },
  { id: 11, value: "전기차" },
  { id: 12, value: "신재생에너지" },
  { id: 13, value: "스마트시티" },
  { id: 14, value: "디지털트윈" },
  { id: 15, value: "사이버보안" },
  { id: 16, value: "핀테크" },
  { id: 17, value: "우주산업" },
  { id: 18, value: "바이오테크" },
  { id: 19, value: "퀀텀컴퓨팅" },
];

const RegionListData: RegionData[] = [
  {
    id: 1,
    name: "경기도",
    segments: [
      { value: 45, color: "blue" },
      { value: 35, color: "orange" },
      { value: 20, color: "green" },
    ],
  },
  {
    id: 2,
    name: "서울특별시",
    segments: [
      { value: 40, color: "blue" },
      { value: 35, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 3,
    name: "대구광역시",
    segments: [
      { value: 42, color: "blue" },
      { value: 33, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 4,
    name: "제주특별자치도",
    segments: [
      { value: 38, color: "blue" },
      { value: 37, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 5,
    name: "충청남도",
    segments: [
      { value: 43, color: "blue" },
      { value: 32, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 6,
    name: "인천광역시",
    segments: [
      { value: 41, color: "blue" },
      { value: 34, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 7,
    name: "충청북도",
    segments: [
      { value: 44, color: "blue" },
      { value: 31, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 8,
    name: "전라남도",
    segments: [
      { value: 39, color: "blue" },
      { value: 36, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 9,
    name: "서울특별시",
    segments: [
      { value: 41, color: "blue" },
      { value: 34, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 10,
    name: "서울특별시",
    segments: [
      { value: 43, color: "blue" },
      { value: 32, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 11,
    name: "대구광역시",
    segments: [
      { value: 40, color: "blue" },
      { value: 35, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 12,
    name: "제주특별자치도",
    segments: [
      { value: 42, color: "blue" },
      { value: 33, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 13,
    name: "충청남도",
    segments: [
      { value: 44, color: "blue" },
      { value: 31, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 14,
    name: "인천광역시",
    segments: [
      { value: 38, color: "blue" },
      { value: 37, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 15,
    name: "충청북도",
    segments: [
      { value: 41, color: "blue" },
      { value: 34, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
  {
    id: 16,
    name: "전라남도",
    segments: [
      { value: 43, color: "blue" },
      { value: 32, color: "orange" },
      { value: 25, color: "green" },
    ],
  },
];

export {
  viewFilterList,
  videoTypeFilterList,
  durationFilterList,
  uploadTimeFilterList,
  categoryFilterList,
  genreFilterList,
  countryFilterList,
  sortFilterList,
  filterValues,
  recommendKeywords,
  relatedKeywords,
  RegionListData,
};
